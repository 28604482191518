<template>
  <div>
    <v-card>
      <v-container>
        <v-card-title class="headline primary--text">{{ title }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeAsistente">
            Cerrar asistente
          </v-btn>
          <v-btn color="primary" text @click="nextQuestion"> No </v-btn>
          <v-btn
            color="primary"
            text
            @click="saveEmpleadorMonotributista"
            v-if="secondQuestion"
          >
            Es monotributista
          </v-btn>
          <v-btn color="primary" text @click="whereToPush"> Sí </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-container>
    </v-card>
    <v-dialog
      v-if="openModalAgenteCta"
      v-model="openModalAgenteCta"
      max-width="650px"
      @keydown.esc="closeModal"
      persistent
    >
      <DatosAdicionalesAgenteCuenta
        @closeAndReload="closeAndReload"
        :newAfiliado="true"
        :afiliado="afiliado"
        :domicilioAgente="domicilioAgente"
        :nroGrupo="benGrId"
        :periodoDesde="periodoDesde"
        @nextQuestion="nextQuestion"
      />
    </v-dialog>
    <v-dialog
      v-if="openModalMovimientoEmpresa"
      v-model="openModalMovimientoEmpresa"
      max-width="650px"
      @keydown.esc="closeAndReload"
      persistent
    >
      <EditMovimientoEmpresaAfiliado
        @closeAndReload="closeAndReload"
        :benId="benId"
        :fechaConsulta="periodoDesde"
        :newEmpresaMonotributista="newEmpresaMonotributista"
        :cargaEmpleadorModal="cargaEmpleadorModal"
        @nextQuestion="nextQuestion"
      />
    </v-dialog>
    <v-dialog
      v-if="openModalProveedor"
      v-model="openModalProveedor"
      max-width="1000px"
      @keydown.esc="closeModal"
      persistent
    >
      <EditDatosProveedor
        :provId="proveedorId"
        @closeAndReload="closeAndReload"
        @nextQuestion="nextQuestion"
      />
    </v-dialog>
  </div>
</template>

<script>
import DatosAdicionalesAgenteCuenta from "@/components/modules/afiliaciones/afiliados/DatosAdicionalesAgenteCuenta.vue";
import EditDatosProveedor from "@/components/modules/afiliaciones/afiliados/EditDatosProveedor.vue";
import { mapActions } from "vuex";
import EditMovimientoEmpresaAfiliado from "./EditMovimientoEmpresaAfiliado.vue";

export default {
  name: "AsistenteDialog",
  components: {
    DatosAdicionalesAgenteCuenta,
    EditDatosProveedor,
    EditMovimientoEmpresaAfiliado
  },
  props: {
    benGrId: {
      type: Number,
      required: true
    },
    benId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      asistente: true,
      secondQuestion: false,
      firstQuestion: false,
      thirdQuestion: false,
      requestPeriodosAgente: true,
      title: "",
      openModalAgenteCta: false,
      openModalProveedor: false,
      afiliado: {},
      nroGrupo: null,
      openModalMovimientoEmpresa: false,
      cargaEmpleadorModal: false,
      periodoDesde: null,
      newEmpresaMonotributista: null,
      proveedorId: null
    };
  },
  created() {
    this.firstQuestion = true;
    this.title = "¿Desea generar agente de cuenta?";
    if (this.$route.params.newAfiliado) {
      this.afiliado = this.$route.params.newAfiliado;
      this.nroGrupo = this.afiliado.benGrId;
      this.periodoDesde = this.afiliado.fechaAlta;
      this.afiliado.domicilios.forEach(x => {
        if (x.tipoRadId === 1) {
          this.domicilioAgente = {
            localidadId: x.localidadId,
            barrioId: x.barrioId,
            calle: x.calle != null ? x.calle : "S/D",
            numero: x.numero != null ? x.numero : 0,
            piso: x.piso,
            depto: x.depto,
            codigoPostal: x.codigoPostal,
            telParticular: x.telParticular,
            telCelular: x.telCelular
          };
        }
      });
    }
    if (this.$route.params.afiliadoFamiliar) {
      this.nextQuestion();
    }
  },
  methods: {
    ...mapActions({
      postAgenteCuenta: "afiliaciones/postAgenteCuenta",
      saveNuevaEmpresaAfiliado: "afiliaciones/saveNuevaEmpresaAfiliado",
      saveProveedorTitular: "afiliaciones/saveProveedorTitular",
      updateProveedorInBenef: "afiliaciones/updateProveedorInBenef",
      setAlert: "user/setAlert"
    }),
    closeAsistente() {
      this.asistente = false;
      this.$emit("closeAsistente");
    },
    nextQuestion() {
      if (this.firstQuestion === true) {
        this.firstQuestion = false;
        this.title = "¿Desea cargar empleador?";
        this.secondQuestion = true;
      } else {
        if (this.secondQuestion === true) {
          this.secondQuestion = false;
          if (this.$route.params.afiliadoFamiliar === true) {
            this.closeAsistente();
          }
          this.title = "¿Desea generar proveedor?";
          this.thirdQuestion = true;
        } else {
          if (this.thirdQuestion === true) {
            this.closeAsistente();
          }
        }
      }
    },
    closeAndReload() {
      this.openModalAgenteCta = false;
      this.openModalMovimientoEmpresa = false;
      this.cargaEmpleadorModal = false;
    },
    async whereToPush() {
      if (this.firstQuestion === true) {
        this.openModalAgenteCta = true;
        // }
      }
      if (this.secondQuestion === true) {
        // acá abre un modal para relacionar una empresa a un afiliado
        this.openModalMovimientoEmpresa = true;
        this.cargaEmpleadorModal = true;
      }
      if (this.thirdQuestion === true) {
        // carga un proveedor con datos de titular y lo relaciona directamente. ver si mostramos el segumdo mensaje
        // que está en escritorio o si eso lo dejamos a la card de Proveedor directamente
        const proveedor = {
          provId: null,
          provCod: this.afiliado.dNI,
          provNom: `${this.afiliado.apellido.trim()} ${this.afiliado.nombre != null ? this.afiliado.nombre.trim() : ""}`,
          cUIT: this.afiliado.cUIL,
          ivaId: null,
          calle: this.afiliado.domicilios[0].calle != null ? this.afiliado.domicilios[0].calle.trim() : "S/D",
          provNro: this.afiliado.domicilios[0].numero,
          barId: this.afiliado.domicilios[0].barrioId,
          locId: this.afiliado.domicilios[0].localidadId,
          codigoPostal: this.afiliado.domicilios[0].codigoPostal,
          telefono: this.afiliado.domicilios[0].telParticular,
          venAfiId: this.afiliado.venAfiId,
          nombreFantasia: `${this.afiliado.apellido.trim()} ${this.afiliado.nombre != null ? this.afiliado.nombre.trim() : ""}`,
          email: this.afiliado.email,
          observaciones: this.afiliado.domicilios[0].observaciones
        };
        const response = await this.saveProveedorTitular(proveedor);
        if (response.status === 200) {
          this.proveedorId = response.data.data;
          const info = {
            benId: this.benId,
            provId: this.proveedorId
          };
          const res = await this.updateProveedorInBenef(info);
          if (res.status === 200) {
            this.setAlert({
              type: "success",
              message: "Guardado con éxito."
            });
            this.openModalProveedor = true;
          }
        }
      }
    },
    async saveEmpleadorMonotributista() {
      const empMon = {
        empId: null,
        locId: this.afiliado.domicilios[0].localidadId,
        empNombre: `${this.afiliado.apellido.trim()} ${this.afiliado.nombre != null ? this.afiliado.nombre.trim() : ""}`,
        responsable: "",
        cuit: this.afiliado.cUIL,
        direccion: `${this.afiliado.domicilios[0].calle != null ? this.afiliado.domicilios[0].calle.trim() : "S/D"} ${
          this.afiliado.domicilios[0].numero != null ? this.afiliado.domicilios[0].numero : 0
        }`,
        telefono: this.afiliado.domicilios[0].telParticular,
        observacion: this.afiliado.domicilios[0].observaciones,
        codPostal: this.afiliado.domicilios[0].codigoPostal,
        email: this.afiliado.email,
        noValidarCorreDDJJ: null,
        ramaId: null
      };
      this.newEmpresaMonotributista = empMon;
      this.openModalMovimientoEmpresa = true;
    }
  }
};
</script>

<style></style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',{staticClass:"pb-0"},[_c('v-card-title',{staticClass:"pt-0"},[_c('span',{staticClass:"pl-1 primary--text"},[_vm._v(_vm._s(this.formEditTitle))])]),_c('v-card-text',{staticClass:"pb-0"},[_c('v-form',{ref:"form",attrs:{"form":"form","id":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.saveMedicoCabeceraAfiliado()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-autocomplete',{ref:"prestador-autocomplete",attrs:{"items":_vm.prestadores,"search-input":_vm.prestadorAutocompleteInput,"item-text":"value","item-value":"id","filter":_vm.customFilter,"rules":_vm.rules.required,"outlined":"","clearable":"","dense":"","autocomplete":"off","label":"Prestador","hint":"Nombre","persistent-hint":""},on:{"update:searchInput":function($event){_vm.prestadorAutocompleteInput=$event},"update:search-input":function($event){_vm.prestadorAutocompleteInput=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.value))])],1)]}},{key:"append",fn:function(){return [(_vm.prestadoresLoading)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":"28","color":"primary"}}):_vm._e()]},proxy:true}]),model:{value:(_vm.prestadorSelected),callback:function ($$v) {_vm.prestadorSelected=$$v},expression:"prestadorSelected"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('v-menu',{ref:"fecha-desde",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Fecha desde","append-icon":_vm.calendarIcon,"hint":"formato DD/MM/AAAA","onkeypress":"return (event.charCode >= 47 && event.charCode <= 57)","clearable":"","dense":"","outlined":"","autocomplete":"off","rules":_vm.rules.required.concat(
                      _vm.rules.validDate,
                      new Date(_vm.parseDateToIso(_vm.fechaDesdeTextField)) <=
                        new Date(_vm.parseDateToIso(_vm.fechaHastaTextField)) ||
                        'Formato incorrecto'
                    )},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }_vm.menuDesde = false},"blur":function($event){[(_vm.fechaDesde = _vm.parseDateToIso(_vm.fechaDesdeTextField))]},"change":function($event){[(_vm.fechaDesde = _vm.parseDateToIso(_vm.fechaDesdeTextField))]}},model:{value:(_vm.fechaDesdeTextField),callback:function ($$v) {_vm.fechaDesdeTextField=$$v},expression:"fechaDesdeTextField"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDesde),callback:function ($$v) {_vm.menuDesde=$$v},expression:"menuDesde"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"change":function($event){_vm.fechaDesdeTextField = _vm.formatDate(_vm.fechaDesde)},"input":function($event){_vm.menuDesde = false}},model:{value:(_vm.fechaDesde),callback:function ($$v) {_vm.fechaDesde=$$v},expression:"fechaDesde"}})],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('v-menu',{ref:"fecha-hasta",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Fecha hasta","append-icon":_vm.calendarIcon,"onkeypress":"return (event.charCode >= 47 && event.charCode <= 57)","hint":"formato DD/MM/AAAA","clearable":"","dense":"","rules":_vm.rules.required.concat(
                      _vm.rules.validDate,
                      new Date(_vm.parseDateToIso(_vm.fechaDesdeTextField)) <=
                        new Date(_vm.parseDateToIso(_vm.fechaHastaTextField)) ||
                        'Formato incorrecto'
                    ),"outlined":"","autocomplete":"off"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }_vm.menuHasta = false},"blur":function($event){[(_vm.fechaHasta = _vm.parseDateToIso(_vm.fechaHastaTextField))]},"change":function($event){[(_vm.fechaHasta = _vm.parseDateToIso(_vm.fechaHastaTextField))]}},model:{value:(_vm.fechaHastaTextField),callback:function ($$v) {_vm.fechaHastaTextField=$$v},expression:"fechaHastaTextField"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuHasta),callback:function ($$v) {_vm.menuHasta=$$v},expression:"menuHasta"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"change":function($event){_vm.fechaHastaTextField = _vm.formatDate(_vm.fechaHasta)},"input":function($event){_vm.menuHasta = false}},model:{value:(_vm.fechaHasta),callback:function ($$v) {_vm.fechaHasta=$$v},expression:"fechaHasta"}})],1)],1),_c('v-col',{staticClass:"pt-0"},[_c('v-textarea',{attrs:{"auto-grow":"","dense":"","rules":[_vm.rules.maxLength(_vm.observaciones, 200)],"outlined":"","label":"Observaciones","rows":"1"},model:{value:(_vm.observaciones),callback:function ($$v) {_vm.observaciones=$$v},expression:"observaciones"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"type":"submit","disabled":!_vm.isFormValid || _vm.prestadoresLoading,"form":"form","color":"primary"}},[_vm._v(" Guardar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
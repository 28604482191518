<template>
  <v-card>
    <v-container class="pb-0">
      <v-card-title class="pt-0">
        <span class="pl-1 primary--text">{{ this.formEditTitle }}</span>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveMovAporteEsperado()"
        >
          <v-row>
            <!-- Periodo Desde -->
            <v-col cols="12" md="4" sm="12" class="pb-0 mt-0">
              <v-text-field
                dense
                outlined
                v-model="periodoDesde"
                label="Período desde"
                hint="Formato AAAAMM"
                persistent-hint
                type="text"
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                v-mask="'######'"
                :rules="
                  rules.required.concat(
                    [rules.validDateRange(periodoDesde, periodoHasta)],
                    rules.periodoYyyyMm
                  )
                "
              ></v-text-field>
            </v-col>
            <!-- Periodo Hasta -->
            <v-col cols="12" md="4" sm="12" class="pb-0 mt-0">
              <v-text-field
                dense
                outlined
                v-model="periodoHasta"
                label="Período hasta"
                type="text"
                hint="Formato AAAAMM"
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                persistent-hint
                v-mask="'######'"
                :rules="
                  rules.required.concat(
                    [rules.validDateRange(periodoDesde, periodoHasta)],
                    rules.periodoYyyyMm
                  )
                "
              ></v-text-field>
            </v-col>
            <!-- Importe -->
            <v-col cols="12" md="4  " sm="12" class="pb-0 mt-0">
              <currency-input
                label="Importe"
                v-model="importe"
                oulined
                dense="false"
                outlined="false"
                class="right-input"
                :options="currencyOptions"
                :rules="
                  topeImporte != null
                    ? rules.required.concat(
                        !(importe > topeImporte) ||
                          'El importe ingresado supera el tope configurado.',
                        [rules.maxLength(importe, 18)]
                      )
                    : []
                "
              >
              </currency-input>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal()"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
      <v-dialog
        v-if="this.modalSaveAndCloseVigencia === true"
        v-model="modalSaveAndCloseVigencia"
        max-width="40.0rem"
      >
        <v-card>
          <v-container>
            <v-card-text>
              <v-card-title class="headline primary--text">
                Existe una vigencia entre los períodos ingresados. ¿Desea cerrar
                vigencia y crear la nueva?
              </v-card-title>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="closeModalSaveAndCloseVigencia()">
                Cancelar
              </v-btn>
              <v-btn
                text
                color="primary"
                dark
                @click="confirmSaveAndCloseVigencia()"
              >
                Confirmar
              </v-btn>
            </v-card-actions>
          </v-container>
        </v-card>
      </v-dialog>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";

export default {
  name: "EditMovimientoAporteEsperado",
  props: {
    benGrId: { type: Number, required: true, default: null },
    aporteEsperadoId: { type: Number, required: false, default: null },
    topeImporte: { type: Number, required: false }
  },
  directives: { mask },
  components: { CurrencyInput },

  data() {
    return {
      formEditTitle: "Editar aporte esperado",
      calendarIcon: enums.icons.CALENDAR,
      periodoDesde: null,
      periodoHasta: null,
      isFormValid: false,
      fechaAltaTextField: null,
      fechaAlta: null,
      menuAlta: false,
      importe: null,
      rules: rules,
      usuarioAlta: null,
      usuarioModif: null,
      fechaModif: null,
      periodosValid: false,
      currencyOptions: {
        locale: "nl-NL",
        currency: "USD",
        currencyDisplay: "narrowSymbol",
        valueRange: {
          min: 0.0,
          max: 9999999999.99
        },
        precision: undefined,
        hideCurrencySymbolOnFocus: true,
        hideGroupingSeparatorOnFocus: true,
        hideNegligibleDecimalDigitsOnFocus: true,
        autoDecimalDigits: false,
        exportValueAsInteger: false,
        autoSign: true,
        useGrouping: true
      },
      modalSaveAndCloseVigencia: false,
      dataToCloseVigencia: {}
    };
  },

  created() {
    if (this.aporteEsperadoId != null && this.aporteEsperadoId != undefined) {
      this.setAporteEsperado(this.aporteEsperadoId);
    } else {
      this.newAporteEsperado();
    }
    if (this.topeImporte != null)
      this.currencyOptions.valueRange.max = this.topeImporte;
  },
  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
      getAporteEsperadoById: "afiliaciones/getAporteEsperadoById",
      saveAporteEsperado: "afiliaciones/saveAporteEsperado",
      saveAndCloseVigencia: "afiliaciones/saveAndCloseVigencia"
    }),
    newAporteEsperado() {
      this.formEditTitle = "Nuevo aporte esperado";
    },
    async setAporteEsperado() {
      const response = await this.getAporteEsperadoById(this.aporteEsperadoId);
      this.aporteEsperadoId = response.aporteEsperadoId;
      this.benGrId = response.benGrId;
      this.periodoDesde = response.periodoDesde;
      this.periodoHasta = response.periodoHasta;
      this.importe = response.importe;
    },

    async saveMovAporteEsperado() {
      const data = {
        aporteEsperadoId: this.aporteEsperadoId,
        benGrId: this.benGrId,
        importe: this.importe,
        periodoDesde: this.periodoDesde,
        periodoHasta: this.periodoHasta
      };
      const res = await this.saveAporteEsperado(data);
      if (res.data.data.aportesIds != null) {
        this.dataToCloseVigencia = {
          aportesIds: res.data.data.aportesIds,
          periodoDesde: res.data.data.periodoDesde
        };
        this.modalSaveAndCloseVigencia = true;
      } else {
        this.setAlert({ type: "success", message: "Guardado con éxito" });
        this.closeModal();
      }
    },
    async confirmSaveAndCloseVigencia() {
      const closeVigencia = await this.saveAndCloseVigencia(
        this.dataToCloseVigencia
      );
      if (closeVigencia.status === 200) {
        this.saveMovAporteEsperado();
        if (response.status === 200)
          this.setAlert({ type: "success", message: "Guardado con éxito" });
        this.closeModal();
      }
    },
    closeModal() {
      this.$emit("toggleModalEditMovAporteEsperado");
    },
    closeModalSaveAndCloseVigencia() {
      this.modalSaveAndCloseVigencia = false;
      this.dataToCloseVigencia = {};
    }
  }
};
</script>

<style scoped>
.right-input >>> input {
  text-align: right;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"item-key":"benPreId","hide-default-footer":"","items":_vm.medicosCabeceraAfiliado},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"10","align":"start"}},[_c('v-card-title',{staticClass:"pl-1 primary--text"},[_vm._v("Médicos de cabecera del afiliado: "+_vm._s(_vm.afiliadoNom))])],1),_c('v-col',{staticClass:"pt-7",attrs:{"cols":"12","md":"2","align":"end"}},[_c('v-btn',{staticClass:"to-right",attrs:{"color":"primary"},on:{"click":function($event){return _vm.toggleModalEditMedicoCabeceraAfiliado()}}},[_vm._v(" Nuevo ")])],1)],1)],1)]},proxy:true},{key:"item.fechaDesde",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.functionFormatDate(item.fechaDesde))+" ")]}},{key:"item.fechaHasta",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.functionFormatDate(item.fechaHasta))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.toggleModalEditMedicoCabeceraAfiliado(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.editIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Editar registro")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.deleteMedicoCabecera(item.benPreId)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.deleteIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Eliminar registro")])])]}}],null,true)}),(_vm.modalEditMedicoCabeceraAfiliado)?_c('v-dialog',{attrs:{"max-width":"40%","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.toggleModalEditMedicoCabeceraAfiliado()}},model:{value:(_vm.modalEditMedicoCabeceraAfiliado),callback:function ($$v) {_vm.modalEditMedicoCabeceraAfiliado=$$v},expression:"modalEditMedicoCabeceraAfiliado"}},[_c('EditMedicoCabeceraAfiliado',{attrs:{"benId":_vm.benId,"medicoCabecera":_vm.medicoCabecera},on:{"toggleModalEditMedicoCabeceraAfiliado":_vm.toggleModalEditMedicoCabeceraAfiliado}})],1):_vm._e()],1),_c('v-container',{staticClass:"pt-0 mt-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","align":"end"}},[_c('v-btn',{staticClass:"to-right",attrs:{"color":"primary"},on:{"click":_vm.closeModal}},[_vm._v(" Cerrar ")])],1)],1)],1),_c('DeleteDialog',{attrs:{"titleProp":_vm.titleDelete,"openDelete":_vm.showDeleteModal},on:{"update:openDelete":function($event){_vm.showDeleteModal=$event},"update:open-delete":function($event){_vm.showDeleteModal=$event},"onDeleteItem":function($event){return _vm.confirmDelete()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
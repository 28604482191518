<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }} </span>
      </v-card-title>
      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveGerenciadoraGestionAfiliado()"
        >
          <v-row>
            <!-- Gerenciadora -->
            <v-col cols="12" sm="6" md="6" class="pb-0">
              <v-autocomplete
                v-model="gerenciadoraNombre"
                :items="itemsGerenciadoras"
                label="Gerenciadora"
                item-text="value"
                item-value="id"
                outlined
                clearable
                required
                :rules="rules.required"
                dense
              >
              </v-autocomplete>
            </v-col>
            <!-- Nivel -->
            <v-col cols="12" sm="6" md="6" class="pb-0">
              <v-autocomplete 
                v-model="nivelNombre"
                :items="itemsNiveles"
                label="Nivel"
                item-text="value"
                item-value="id"
                outlined
                clearable
                required
                :rules="rules.required"
                dense
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" class="py-0">
              <!-- Fecha desde -->
              <v-menu
                ref="menu"
                v-model="menuDesde"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fechaDesdeTextField"
                    label="Fecha desde"
                    :append-icon="calendarIcon"
                    v-mask="'##/##/####'"
                    hint="formato DD/MM/AAAA"
                    @keydown.tab="menuDesde = false"
                    onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                    @blur="[(fechaDesde = parseDateToIso(fechaDesdeTextField))]"
                    @change="
                      [(fechaDesde = parseDateToIso(fechaDesdeTextField))]
                    "
                    clearable
                    dense
                    outlined
                    autocomplete="off"
                    :rules="
                      rules.required.concat(
                        rules.validDate,
                        new Date(parseDateToIso(fechaDesdeTextField)) <=
                          new Date(parseDateToIso(fechaHastaTextField)) ||
                          'Formato incorrecto'
                      )
                    "
                    v-bind="attrs"
                    v-on="on"
                  >
                  </v-text-field>
                </template>

                <v-date-picker
                  v-model="fechaDesde"
                  no-title
                  scrollable
                  @change="fechaDesdeTextField = formatDate(fechaDesde)"
                  @input="menuDesde = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <!-- Fecha hasta -->

              <v-menu
                ref="menu2"
                v-model="menuHasta"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fechaHastaTextField"
                    label="Fecha hasta"
                    :append-icon="calendarIcon"
                    @keydown.tab="menuHasta = false"
                    v-mask="'##/##/####'"
                    onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                    hint="formato DD/MM/AAAA"
                    @blur="[(fechaHasta = parseDateToIso(fechaHastaTextField))]"
                    @change="
                      [(fechaHasta = parseDateToIso(fechaHastaTextField))]
                    "
                    clearable
                    dense
                    :rules="
                      rules.validDate.concat(
                        new Date(parseDateToIso(fechaDesdeTextField)) <=
                          new Date(parseDateToIso(fechaHastaTextField)) ||
                          'Formato incorrecto'
                      )
                    "
                    outlined
                    autocomplete="off"
                    v-bind="attrs"
                    v-on="on"
                  >
                  </v-text-field>
                </template>

                <v-date-picker
                  v-model="fechaHasta"
                  no-title
                  scrollable
                  @change="fechaHastaTextField = formatDate(fechaHasta)"
                  @input="menuHasta = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <!-- Observaciones -->
            <v-col cols="12" sm="6" md="12" class="pt-0 py-0">
              <v-textarea
                v-model="observaciones"
                auto-grow
                dense
                :rules="[rules.maxLength(observaciones, 250)]"
                outlined
                label="Observaciones"
                rows="1"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
      <span> </span>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";
import moment from "moment";

export default {
  name: "EditGerenciadorasDeAfiliado",

  props: {
    benId: { type: Number, required: false, default: null },
    gerenciadorasParams: { type: Object }
  },

  directives: { mask },
  data: vm => ({
    isFormValid: false,
    formEditTitle: enums.titles.EDITAR_GERENCIADORA_AFILIADO,
    newTitle: enums.titles.NUEVO_GERENCIADORA_AFILIADO,
    calendarIcon: enums.icons.CALENDAR,
    rules: rules,
    menuDesde: null,
    fechaDesdeTextField: null,
    fechaHastaTextField: null,
    gerenciadoraNombre: "",
    itemsGerenciadoras: [],
    nivelNombre: "",
    itemsNiveles: [],
    menuDesde: null,
    fechaDesde: null,
    menuHasta: null,
    fechaHasta: null,
    observaciones: null,
  }),
  created() {
    if (this.gerenciadorasParams) {
      this.setGerenciadorasDeAfiliado(this.gerenciadorasParams);
    } else {
      this.newGerenciadoraDeAfiliado();
    }
    this.setGerenciadoras();
    this.setNivelesAtencion();
  },
  methods: {
    ...mapActions({
      saveGerenciadoraBenef: "afiliaciones/saveGerenciadoraBenef",
      getGerenciadoras: "afiliaciones/getGerenciadoras",
      getNivelesAtencion: "afiliaciones/getNivelesAtencion",
      setAlert: "user/setAlert"
    }),

    async setGerenciadoras() {
      const response = await this.getGerenciadoras();
      this.itemsGerenciadoras = response.data;
    },
    async setNivelesAtencion() {
      const response = await this.getNivelesAtencion();
      this.itemsNiveles = response.data;
    },
    functionFormatDate(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }
    },
    async setGerenciadorasDeAfiliado(obj) {
      this.fechaDesdeTextField = obj.fechaDesde;
      this.fechaDesde = this.parseDateToIso(obj.fechaDesde);
      this.fechaHastaTextField = obj.fechaHasta;
      this.fechaHasta = this.parseDateToIso(obj.fechaHasta);
      this.nivelNombre = obj.nivelId;
      this.gerenciadoraNombre = obj.gerId;
      this.observaciones = obj.observaciones
    },
    async newGerenciadoraDeAfiliado() {
      this.formEditTitle = this.newTitle;
    },
    async saveGerenciadoraGestionAfiliado() {
      const data = {
        benId: this.benId,
        gerBenId: this.gerenciadorasParams?.gerBenId,
        fechaDesde: this.fechaDesde,
        fechaHasta: this.fechaHasta,
        nivelId: this.nivelNombre,
        gerId: this.gerenciadoraNombre,
        observaciones: this.observaciones
      };
      const res = await this.saveGerenciadoraBenef(data);
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito" });
        this.closeModal();
      }
    },
    closeModal() {
      this.$emit("closeAndReload");
    },
    // metodos date picker
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    }
  }
};
</script>

<style></style>

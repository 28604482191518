<template>
  <v-card>
    <v-container class="pb-0">
      <v-card-title class="pt-0">
        <span class="pl-1 primary--text">{{ this.formEditTitle }}</span>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveMedicoCabeceraAfiliado()"
        >
          <v-row>
            <!-- Prestador -->
            <v-col cols="12" class="py-0">
              <v-autocomplete
                ref="prestador-autocomplete"
                v-model="prestadorSelected"
                :items="prestadores"
                :search-input.sync="prestadorAutocompleteInput"
                item-text="value"
                item-value="id"
                :filter="customFilter"
                :rules="rules.required"
                outlined
                clearable
                dense
                autocomplete="off"
                label="Prestador"
                hint="Nombre"
                persistent-hint
              >
                <template slot="item" slot-scope="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.value }}</v-list-item-title>
                  </v-list-item-content>
                </template>
                <template v-slot:append>
                  <v-progress-circular
                    indeterminate
                    size="28"
                    v-if="prestadoresLoading"
                    color="primary"
                  ></v-progress-circular>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <!-- Fecha de vigencia desde -->
              <v-menu
                ref="fecha-desde"
                v-model="menuDesde"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fechaDesdeTextField"
                    label="Fecha desde"
                    :append-icon="calendarIcon"
                    v-mask="'##/##/####'"
                    hint="formato DD/MM/AAAA"
                    @keydown.tab="menuDesde = false"
                    onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                    @blur="[(fechaDesde = parseDateToIso(fechaDesdeTextField))]"
                    @change="
                      [(fechaDesde = parseDateToIso(fechaDesdeTextField))]
                    "
                    clearable
                    dense
                    outlined
                    autocomplete="off"
                    :rules="
                      rules.required.concat(
                        rules.validDate,
                        new Date(parseDateToIso(fechaDesdeTextField)) <=
                          new Date(parseDateToIso(fechaHastaTextField)) ||
                          'Formato incorrecto'
                      )
                    "
                    v-bind="attrs"
                    v-on="on"
                  >
                  </v-text-field>
                </template>

                <v-date-picker
                  v-model="fechaDesde"
                  no-title
                  scrollable
                  @change="fechaDesdeTextField = formatDate(fechaDesde)"
                  @input="menuDesde = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <!-- Fecha de vigencia hasta -->

              <v-menu
                ref="fecha-hasta"
                v-model="menuHasta"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fechaHastaTextField"
                    label="Fecha hasta"
                    :append-icon="calendarIcon"
                    @keydown.tab="menuHasta = false"
                    v-mask="'##/##/####'"
                    onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                    hint="formato DD/MM/AAAA"
                    @blur="[(fechaHasta = parseDateToIso(fechaHastaTextField))]"
                    @change="
                      [(fechaHasta = parseDateToIso(fechaHastaTextField))]
                    "
                    clearable
                    dense
                    :rules="
                      rules.required.concat(
                        rules.validDate,
                        new Date(parseDateToIso(fechaDesdeTextField)) <=
                          new Date(parseDateToIso(fechaHastaTextField)) ||
                          'Formato incorrecto'
                      )
                    "
                    outlined
                    autocomplete="off"
                    v-bind="attrs"
                    v-on="on"
                  >
                  </v-text-field>
                </template>

                <v-date-picker
                  v-model="fechaHasta"
                  no-title
                  scrollable
                  @change="fechaHastaTextField = formatDate(fechaHasta)"
                  @input="menuHasta = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <!-- Observaciones -->
            <v-col class="pt-0">
              <v-textarea
                v-model="observaciones"
                auto-grow
                dense
                :rules="[rules.maxLength(observaciones, 200)]"
                outlined
                label="Observaciones"
                rows="1"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal()">
          Cancelar
        </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid || prestadoresLoading"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import { mask } from "vue-the-mask";
import moment from "moment";
import enums from "@/utils/enums/index.js";

export default {
  name: "EditMedicoCabeceraAfiliado",
  props: {
    benId: { type: Number, required: true },
    medicoCabecera: { type: Object, required: false, default: null }
  },
  directives: { mask },
  data() {
    return {
      formEditTitle: "Editar médico de cabecera",
      calendarIcon: enums.icons.CALENDAR,
      isFormValid: false,
      rules: rules,
      timer: null,
      prestadorSelected: null,
      prestadores: [],
      prestadorAutocompleteInput: null,
      prestadoresLoading: null,
      menuDesde: null,
      observaciones: null,
      menuHasta: null,
      fechaDesde: null,
      fechaHasta: null,
      fechaDesdeTextField: null,
      fechaHastaTextField: null,
      banderaPrestadorSelected: false,
      benPreId: null
    };
  },
  watch: {
    prestadorAutocompleteInput(val) {
      if (val && val.length >= 3) {
        this.getPrestadoresFilter();
      }
    }
  },
  created() {
    if (this.medicoCabecera != null) {
      this.banderaPrestadorSelected = true;
      this.setMedicoCabecera();
    } else {
      this.newMedicoCabecera();
    }
  },
  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
      saveMedicosCabeceraAfiliado: "afiliaciones/saveMedicosCabeceraAfiliado",
      fetchPrestadoresByQuery: "afiliaciones/fetchPrestadoresByQuery"
    }),
    newMedicoCabecera() {
      this.formEditTitle = "Nuevo médico de cabecera";
    },
    async setMedicoCabecera() {
      this.benPreId = this.medicoCabecera.benPreId;
      this.prestadorAutocompleteInput = this.medicoCabecera.prestadorNom; // refaccionar, si hay 2 con el mismo nombre funciona mal
      this.fechaDesde = this.medicoCabecera.fechaDesde;
      this.fechaDesdeTextField = this.functionFormatDate(
        this.medicoCabecera.fechaDesde
      );
      this.fechaHasta = this.medicoCabecera.fechaHasta;
      this.fechaHastaTextField = this.functionFormatDate(
        this.medicoCabecera.fechaHasta
      );
      this.observaciones = this.medicoCabecera.observaciones;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    functionFormatDate(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }
    },
    async saveMedicoCabeceraAfiliado() {
      const data = {
        benPreId: this.benPreId,
        benId: this.benId,
        prestadorId: this.prestadorSelected,
        fechaDesde: this.fechaDesde,
        fechaHasta: this.fechaHasta,
        observaciones: this.observaciones
      };
      const res = await this.saveMedicosCabeceraAfiliado(data);
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito" });
        this.closeModal();
      }
    },
    closeModal() {
      this.$emit("toggleModalEditMedicoCabeceraAfiliado");
    },
    getPrestadoresFilter() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(async () => {
        if (
          this.prestadores?.some(
            x => x.value == this.prestadorAutocompleteInput
          )
        ) {
          return;
        } else if (!this.banderaPrestadorSelected) {
          this.prestadoresLoading = true;
          const res = await this.fetchPrestadoresByQuery({
            input: this.prestadorAutocompleteInput
          });
          this.$refs["prestador-autocomplete"].cachedItems = [];
          this.prestadores = res;
          this.prestadoresLoading = false;
        } else {
          this.prestadoresLoading = true;
          const response = await this.fetchPrestadoresByQuery({
            input: this.medicoCabecera.prestadorId
          });
          this.prestadores = response;
          this.prestadorSelected = response[0].id;
          // ^^ evita que se borre el campo empresaAutocompleteInput al setear los datos
          this.banderaPrestadorSelected = false;
          this.prestadoresLoading = false;
        }
      }, 800);
    },
    customFilter(item, queryText, itemText) {
      return item.id || item.value.indexOf(queryText) > -1;
    }
  }
};
</script>

<style></style>

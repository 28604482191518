<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>

      <v-card-text class="pb-0">
        <v-form
          v-model="isFormValid"
          ref="empresa-form"
          form="empresa-form"
          id="empresa-form"
          @submit.prevent="saveEmpresaAfiliado()"
        >
          <v-row>
            <!-- Empresa -->
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                v-model.trim="empNombre"
                label="Nombre de la empresa"
                dense
                outlined
                :rules="
                  rules.required.concat([
                    rules.requiredTrim(empNombre),
                    rules.maxLength(empNombre, 100)
                  ])
                "
              ></v-text-field>
            </v-col>
            <!-- Responsable -->
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                v-model="responsable"
                label="Responsable"
                dense
                outlined
                :rules="
                  responsable !== null
                    ? [rules.maxLength(responsable, 100)]
                    : []
                "
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <!-- Direccion -->
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                v-model.trim="direccion"
                label="Dirección"
                dense
                outlined
                :rules="
                  rules.required.concat([
                    rules.requiredTrim(direccion),
                    rules.maxLength(direccion, 120)
                  ])"
              ></v-text-field>
            </v-col>
            <!-- Localidad -->
            <v-col cols="12" md="6" class="py-0">
              <v-autocomplete
                v-model="localidadSelected"
                ref="localidad"
                :items="localidades"
                item-text="value"
                item-value="id"
                label="Localidad"
                outlined
                autocomplete="off"
                clearable
                dense
                :rules="rules.required"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row justify="space-between">
            <!-- CUIT -->
            <v-col cols="12" md="4" class="py-0">
              <v-text-field
                v-model="cuit"
                label="CUIT"
                dense
                @blur="cuit !== null ? validarCUIL(cuit) : []"
                outlined
                v-mask="'##-########-#'"
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                :rules="rules.required.concat(rules.minLength(cuit, 13))"
              ></v-text-field>
            </v-col>
            <!-- Rama -->
            <v-col cols="12" md="4" class="py-0">
              <v-autocomplete
                v-model="ramaSelected"
                ref="rama"
                :items="ramas"
                item-text="value"
                item-value="id"
                label="Rama sindical"
                outlined
                dense
              >
              </v-autocomplete>
            </v-col>

            <!-- Codigo postal empresa -->
            <v-col cols="12" md="4" class="py-0">
              <v-text-field
                v-model="empCodigoPostal"
                label="Código postal"
                type="text"
                dense
                outlined
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                :rules="rules.validCodigoPostal"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <!-- Celular -->
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                v-model="telefono"
                label="Celular"
                type="text"
                dense
                outlined
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                :rules="rules.required.concat([rules.maxLength(telefono, 100)])"
              ></v-text-field>
            </v-col>

            <!-- Email -->
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                v-model="empEmail"
                label="Email"
                dense
                outlined
                :rules="rules.email.concat([rules.maxLength(empEmail, 200)])"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <!-- Observaciones -->
            <v-col cols="12" class="py-0">
              <v-textarea
                v-model="observacion"
                label="Observaciones"
                dense
                outlined
                auto-grow
                rows="1"
                :rules="
                  observacion !== null
                    ? [rules.maxLength(observacion, 254)]
                    : []
                "
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal()"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="empresa-form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import validateCUIL from "@/utils/helpers/validateCUIL";
import { mask } from "vue-the-mask";

export default {
  name: "NuevaEmpresaAfiliado",
  props: {
    newEmpresaMonotributista: { type: Object, required: false, default: null },
    cargaEmpleadorModal: { type: Boolean }
  },
  directives: { mask },
  data() {
    return {
      formEditTitle: "Nueva empresa",
      isFormValid: false,
      rules: rules,
      empId: null,
      localidadSelected: null,
      localidades: [],
      empNombre: null,
      responsable: null,
      cuit: null,
      direccion: null,
      telefono: null,
      observacion: null,
      empCodigoPostal: null,
      empEmail: null,
      ramaSelected: null,
      ramas: [],
      empNoValidarCorreDdjj: null
    };
  },
  created() {
    this.setSelects();
    if (this.newEmpresaMonotributista) {
      if (this.cargaEmpleadorModal) {
        this.resetForm;
      } else {
        this.setDatosFromAsistente();
      }
    }
  },
  methods: {
    ...mapActions({
      getLocalidades: "afiliaciones/getLocalidades",
      saveNuevaEmpresaAfiliado: "afiliaciones/saveNuevaEmpresaAfiliado",
      getRamasSindicales: "afiliaciones/getRamasSindicales",
      setAlert: "user/setAlert"
    }),
    setDatosFromAsistente() {
      this.empNombre = this.newEmpresaMonotributista.empNombre;
      this.responsable = this.newEmpresaMonotributista.responsable;
      this.direccion = this.newEmpresaMonotributista.direccion;
      this.localidadSelected = this.newEmpresaMonotributista.locId;
      this.cuit = this.newEmpresaMonotributista.cuit;
      this.ramaSelected = this.newEmpresaMonotributista.ramaId;
      this.empCodigoPostal = this.newEmpresaMonotributista.codPostal;
      this.telefono = this.newEmpresaMonotributista.telefono;
      this.empEmail = this.newEmpresaMonotributista.email;
      this.observacion = this.newEmpresaMonotributista.observacion;
      if (this.cuit === null) {
        this.setAlert({ type: "info", message: "Ingrese el CUIT " });
      }
    },
    async setSelects() {
      const locResponse = await this.getLocalidades();
      const ramaResponse = await this.getRamasSindicales();
      this.ramas = ramaResponse;
      this.localidades = locResponse;
    },
    async saveEmpresaAfiliado() {
      const data = {
        empId: this.empId,
        locId: this.localidadSelected,
        empNombre: this.empNombre.toUpperCase(),
        responsable: this.responsable,
        cuit: this.cuit.replaceAll("-", ""),
        direccion: this.direccion.toUpperCase(),
        telefono: this.telefono,
        observacion: this.observacion,
        codPostal: this.empCodigoPostal,
        email: this.empEmail,
        noValidarCorreDdjj: this.empNoValidarCorreDdjj,
        ramaId: this.ramaSelected
      };
      const res = await this.saveNuevaEmpresaAfiliado(data);
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito" });
        this.$emit("empresaCreada", this.empNombre);
        this.$emit("cuilEmpresaCreada", this.cuit.replaceAll("-", ""));
        this.closeModal();
      }
    },
    validarCUIL(cuil) {
      const res = validateCUIL.validateCUITCUIL(cuil);
      if (res === false) {
        this.setAlert({
          type: "warning",
          message: "El CUIL ingresado es incorrecto."
        });
        this.isFormValid = false;
      } else if (
        this.empNombre !== null &&
        this.telefono !== null &&
        this.localidadSelected !== null &&
        this.direccion !== null
      ) {
        this.isFormValid = true;
      }
    },
    resetForm() {
      this.$refs["empresa-form"].reset();
    },
    closeModal() {
      this.$emit("toggleModalNuevaEmpresa", this.empNombre);
    }
  }
};
</script>

<style></style>

<template>
  <v-card>
    <v-container>
      <v-card-title class="primary--text">
        {{ titleCard }}
      </v-card-title>
      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveEdit()"
        >
          <v-row>
            <!-- Número -->
            <v-col cols="12" sm="6" md="6" class="pb-0">
              <v-text-field
                type="text"
                outlined
                dense
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                :rules="
                  rules.required.concat([rules.maxLength(numeroAfiliacion, 16)])
                "
                label="Número de afiliación"
                v-model="numeroAfiliacion"
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <!-- Tipo Afiliacion -->
            <v-col cols="12" sm="6" md="6" class="pb-0">
              <v-select
                v-model="tipoAfiliacionSelected"
                :items="tiposAfiliaciones"
                label="Tipo de afiliación"
                item-text="value"
                item-value="id"
                outlined
                clearable
                required
                :rules="rules.required"
                dense
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <!-- Fecha desde -->
            <v-col cols="12" sm="6" md="6" class="py-0">
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fechaDesdeSelected"
                    label="Fecha desde"
                    :append-icon="calendarIcon"
                    v-bind="attrs"
                    autocomplete="not"
                    hint="Formato DD/MM/AAAA"
                    @blur="fechaDesde = parseDateToIso(fechaDesdeSelected)"
                    outlined
                    dense
                    v-on="on"
                    :rules="
                      rules.required.concat(
                        new Date(parseDateToIso(fechaDesdeSelected)) <=
                          new Date(parseDateToIso(fechaHastaSelected))
                      ) || 'Formato incorrecto'
                    "
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaDesde"
                  no-title
                  @change="fechaDesdeSelected = formatDate(fechaDesde)"
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <!-- Fecha hasta -->
            <v-col cols="12" sm="6" md="6" class="py-0">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fechaHastaSelected"
                    label="Fecha hasta"
                    :append-icon="calendarIcon"
                    hint="Formato DD/MM/AAAA"
                    @blur="fechaHasta = parseDateToIso(fechaHastaSelected)"
                    outlined
                    autocomplete="not"
                    dense
                    v-bind="attrs"
                    v-on="on"
                    :rules="
                      rules.required.concat(
                        new Date(parseDateToIso(fechaDesdeSelected)) <=
                          new Date(parseDateToIso(fechaHastaSelected))
                      ) || 'Formato incorrecto'
                    "
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaHasta"
                  no-title
                  @change="fechaHastaSelected = formatDate(fechaHasta)"
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <!-- Observaciones -->
            <v-col class="pt-0">
              <v-textarea
                v-model="observaciones"
                auto-grow
                :rules="
                  observaciones !== null
                    ? [rules.maxLength(observaciones, 100)]
                    : []
                "
                dense
                outlined
                label="Observaciones"
                rows="1"
              ></v-textarea
            ></v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal()">
          Cerrar
        </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
export default {
  props: {
    afiliacion: {
      type: Object,
      required: false
    },
    titleCardProp: {
      type: String,
      required: false
    },
    benId: {
      type: Number,
      required: false
    },
    listAfiliaciones: {
      type: Array,
      required: false
    }
  },

  data() {
    return {
      titleCard: "",
      calendarIcon: enums.icons.CALENDAR,
      menu1: false,
      menu2: false,
      isFormValid: false,
      observaciones: "",
      fechaHastaSelected: null,
      fechaDesdeSelected: null,
      OtraAfiliacionId: 0,
      fechaDesde: null,
      fechaHasta: null,
      tiposAfiliaciones: [],
      numeroAfiliacion: null,
      tipoAfiliacionSelected: null,
      rules: rules
    };
  },
  created() {
    this.loadTipoAfiliacion();
    this.titleCard = this.titleCardProp;
    if (this.afiliacion != null) {
      this.setAfiliacion(this.afiliacion);
    } else {
      (this.observaciones = null),
        (this.fechaHastaSelected = null),
        (this.fechaDesdeSelected = null),
        (this.fechaDesde = null),
        (this.fechaHasta = null),
        (this.numeroAfiliacion = null),
        (this.tipoAfiliacionSelected = null);
    }
  },
  methods: {
    ...mapActions({
      saveOtraAfiliacion: "afiliaciones/saveOtraAfiliacion",
      getOtroTipoNumeroAfiliado: "afiliaciones/getOtroTipoNumeroAfiliado",
      setAlert: "user/setAlert"
    }),

    async loadTipoAfiliacion() {
      let response = await this.getOtroTipoNumeroAfiliado();
      this.tiposAfiliaciones = response.data;
    },
    closeModal() {
      this.$emit("closeModalEditAfiliacion");
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    async setAfiliacion(item) {
      this.OtraAfiliacionId = item.otraAfiliacionId;
      this.observaciones = item.observaciones;
      this.fechaHastaSelected = item.fechaHasta;
      this.fechaDesdeSelected = item.fechaDesde;
      this.numeroAfiliacion = item.numero;
      this.tipoAfiliacionSelected = item.otroTipoAfiId;
    },
    async saveEdit() {
      const data = {
        OtraAfiliacionId: this.OtraAfiliacionId,
        OtroTipoNumeroAfiliadoId: this.tipoAfiliacionSelected,
        BenId: this.benId,
        Numero: this.numeroAfiliacion,
        FechaDesde: this.parseDateToIso(this.fechaDesdeSelected),
        FechaHasta: this.parseDateToIso(this.fechaHastaSelected),
        Observaciones: this.observaciones !== null ?  this.observaciones : null
      };
      const response = await this.saveOtraAfiliacion(data);
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Guardado con éxito" });
        this.closeModal();
      }}
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
};
</script>

<style></style>

<template>
  <v-card>
    <v-container>
      <v-data-table
        :headers="liquidacionesHeaders"
        :items="liquidacionesItems"
        class="elevation-1"
        item-key="vendedoresLiqProcDetaId"
        :loading="liquidacionesIsLoading"
        show-expand
        single-expand
        :expanded.sync="expanded"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-row>
              <v-col cols="3">
                <v-card-title class="pl-1 primary--text"
                  >{{ title }}
                </v-card-title>
              </v-col>
              <v-spacer></v-spacer>
              <v-col :cols="movHasLiqDesvinculadas ? '3' : '8'" class="pt-5">
                <v-btn
                  class="to-right"
                  outlined
                  :color="'red'"
                  @click="unlinkAllLiquidaciones()"
                >
                  Desvincular todas
                </v-btn>
              </v-col>
              <v-col cols="1" class="pt-5 pr-0">
                <v-tooltip bottom max-width="30%">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="pt-2"
                      v-on="on"
                      v-bind="attrs"
                      color="primary"
                    >
                      {{ infoIcon }}</v-icon
                    >
                  </template>
                  <span
                    >Se desvincularán todas las liquidaciones del movimiento
                    seleccionado.
                  </span>
                </v-tooltip>
              </v-col>
              <v-col cols="4" class="pt-5" v-if="movHasLiqDesvinculadas">
                <v-btn
                  class="to-right"
                  color="primary"
                  @click="linkOrUnlinkLiquidacion()"
                >
                  Vincular liquidaciones
                </v-btn>
              </v-col>
              <v-col cols="1" class="pt-5" v-if="movHasLiqDesvinculadas">
                <v-tooltip bottom max-width="30%">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="pt-2"
                      v-on="on"
                      v-bind="attrs"
                      color="primary"
                    >
                      {{ infoIcon }}</v-icon
                    >
                  </template>
                  <span
                    >Se volverán a vincular todas las liquidaciones que
                    anteriormente pertenecían al movimiento seleccionado.
                  </span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                @click="linkOrUnlinkLiquidacion(item)"
              >
                {{ desvincularIcon }}
              </v-icon>
            </template>
            <span>Desvincular liquidación</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.data-table-expand`]="{ item, isExpanded }">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                @click="handleExpansion(item, isExpanded)"
                v-bind="attrs"
                size="20"
                v-on="on"
                >{{ isExpanded ? "mdi-account-check" : "mdi-account" }}</v-icon
              >
            </template>
            <span>Usuarios</span>
          </v-tooltip>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <strong>Fecha alta:</strong>
            {{ item.fechaAlta }}
            <strong style="padding-left: 100px">Usuario alta:</strong>
            {{ item.usuarioAlta }}
          </td>
        </template>
      </v-data-table>
      <DeleteDialog
        :titleProp="titleDelete"
        :maxWidth="'30%'"
        :textDelete="textDelete"
        :isLoading="btnDeleteIsLoading"
        :confirmButtonText="textBtnDelete"
        :openDelete.sync="showDeleteModal"
        @onDeleteItem="confirmDelete()"
      />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal()"> Cerrar </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import DeleteDialog from "@/components/shared/DeleteDialog";

export default {
  name: "VerLiquidacionesMovimientos",
  components: {
    DeleteDialog
  },
  props: {
    benId: {
      type: Number,
      required: true
    },
    movItem: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      // title: `Liquidaciones mov: ${this.movItem.movNom}`,
      title: "Liquidaciones",
      vincularIcon: enums.icons.LINK_PLUS,
      desvincularIcon: enums.icons.LINK_MINUS,
      infoIcon: enums.icons.SNB_INFO,
      showDeleteModal: false,
      idToSend: null,
      titleDelete: "",
      textDelete: "",
      liquidacionesItems: [],
      liquidacionesHeaders: [
        {
          text: "Tipo comprobante",
          value: "tipoCompNom",
          sortable: false
        },
        {
          text: "Sucursal",
          value: "sucursal",
          align: "end",
          sortable: false
        },
        {
          text: "Número",
          value: "numero",
          align: "end",
          sortable: false
        },
        {
          text: "Fecha",
          value: "fecha",
          sortable: false
        },
        {
          text: "Importe total",
          value: "importeTotal",
          sortable: false,
          align: "end"
        },
        {
          text: "Importe cancelado",
          value: "importeCancelado",
          sortable: false,
          align: "end"
        },
        {
          text: "Saldo",
          value: "saldo",
          sortable: false,
          align: "end"
        },
        { text: "Acciones", value: "actions", sortable: false, align: "end" },
        { text: "", value: "data-table-expand", align: "end" }
      ],
      liquidacionesIsLoading: false,
      expanded: [],
      isDesvincularAction: false,
      isVincularAction: false,
      textBtnDelete: "",
      movHasLiqDesvinculadas: false,
      btnDeleteIsLoading: false,
      unlinkAll: false
    };
  },
  created() {
    this.setLiquidaciones();
    this.validateLiquidacionesMov();
  },
  methods: {
    ...mapActions({
      desvincularLiquidacionMovimientoAfiliado:
        "configAfiliaciones/desvincularLiquidacionMovimientoAfiliado",
      vincularLiquidacionMovAfiliado:
        "configAfiliaciones/vincularLiquidacionMovAfiliado",
      movimientoHasLiquidacionesDesvinculadas:
        "configAfiliaciones/movimientoHasLiquidacionesDesvinculadas",
      setAlert: "user/setAlert"
    }),
    async validateLiquidacionesMov() {
      const response = await this.movimientoHasLiquidacionesDesvinculadas(
        this.movItem.bcId
      );
      if (response.status === 200) {
        if (response.data.data) this.movHasLiqDesvinculadas = true;
      }
    },
    closeModal() {
      this.$emit("closeModal");
    },
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
    setLiquidaciones() {
      this.liquidacionesIsLoading = true;
      this.liquidacionesItems = this.movItem.liquidaciones;
      this.liquidacionesIsLoading = false;
    },
    unlinkAllLiquidaciones() {
      this.titleDelete = "¿Desvincular liquidaciones?";
      this.textBtnDelete = "Desvincular";
      this.textDelete =
        "Todas las liquidaciones serán desvinculadas del movimiento seleccionado.";
      this.unlinkAll = true;
      this.isDesvincularAction = false;
      this.isVincularAction = false;
      this.idToSend = this.liquidacionesItems.map(
        x => x.vendedoresLiqProcDetaId
      );
      this.showDeleteModal = true;
    },
    linkOrUnlinkLiquidacion(item) {
      if (item != null) {
        if (item.liquidado) {
          this.isDesvincularAction = true;
          this.isVincularAction = false;
          this.unlinkAll = false;
          this.idToSend = item.vendedoresLiqProcDetaId;
          this.titleDelete = "¿Desvincular liquidación?";
          this.textBtnDelete = "Desvincular";
          this.textDelete =
            "La liquidación quedará desvinculada del movimiento seleccionado.";
        }
      } else {
        this.isVincularAction = true;
        this.unlinkAll = false;
        this.isDesvincularAction = false;
        this.idToSend = this.movItem.bcId;
        this.textBtnDelete = "Vincular";
        this.titleDelete = "¿Vincular liquidaciones?";
        this.textDelete =
          "Todas las liquidaciones desvinculadas volverán a ser vinculadas al movimiento seleccionado.";
      }
      this.showDeleteModal = true;
    },
    async confirmDelete() {
      this.btnDeleteIsLoading = true;
      try {
        if (this.unlinkAll) {
          await Promise.all(
            this.idToSend.map(async element => {
              try {
                await this.desvincularLiquidacionMovimientoAfiliado(element);
              } catch (error) {
                this.setAlert({
                  type: "warning",
                  message:
                    "No fue posible desvincular una o más liquidaciones del movimiento."
                });
                throw error;
              }
            })
          );
          this.setAlert({
            type: "success",
            message: "Todas las liquidaciones han sido desvinculadas con éxito."
          });
          this.closeModal();
        } else if (this.isDesvincularAction) {
          const response = await this.desvincularLiquidacionMovimientoAfiliado(
            this.idToSend
          );
          if (response.status === 200) {
            this.setAlert({
              type: "success",
              message: "Liquidación desvinculada con éxito."
            });
            await this.validateLiquidacionesMov();
            if (this.movHasLiqDesvinculadas) {
              this.liquidacionesItems = this.liquidacionesItems.filter(
                x => x.vendedoresLiqProcDetaId != this.idToSend
              );
              this.isDesvincularAction = false;
              this.showDeleteModal = false;
            } else this.closeModal();
          }
        } else {
          const response = await this.vincularLiquidacionMovAfiliado(
            this.idToSend
          );
          if (response.status === 200) {
            this.setAlert({
              type: "success",
              message: "Liquidaciones vinculadas con éxito."
            });
            this.closeModal();
          }
        }
      } catch (error) {
      } finally {
        this.btnDeleteIsLoading = false;
      }
    }
  }
};
</script>

<style></style>

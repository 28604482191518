<template>
  <v-card>
    <v-container>
      <v-card-title class="pt-0">
        <v-row>
          <v-col cols="10">
            <span class="primary--text">{{ title }}</span></v-col
          >
        </v-row>
      </v-card-title>
      <v-card-text class="py-0 mb-0">
        <v-row justify="space-around">
          <!-- numero -->
          <v-col
            cols="12"
            sm="6"
            md="2"
            class="py-0 my-0 d-flex justify-center"
          >
            <v-text-field
              type="text"
              outlined
              dense
              filled
              disabled
              readonly
              class="theme--light.v-input input"
              label="Número de afiliado"
              v-model="afiliado.id"
              autocomplete="off"
            ></v-text-field>
          </v-col>
          <!-- nombre y apellido -->
          <v-col
            cols="12"
            sm="6"
            md="5"
            class="py-0 my-0 d-flex justify-center"
          >
            <v-text-field
              v-model="afiliado.name"
              label="Apellido y nombre de afiliado"
              filled
              disabled
              readonly
              class="theme--light.v-input input"
              outlined
              dense
              type="text"
              autocomplete="off"
            >
            </v-text-field>
          </v-col>
          <!-- Tipo de documento -->
          <v-col
            cols="12"
            sm="6"
            md="2"
            class="py-0 my-0 d-flex justify-center"
          >
            <v-text-field
              type="text"
              outlined
              dense
              disabled
              filled
              readonly
              class="theme--light.v-input input"
              label="Tipo de documento"
              v-model="afiliado.tipoDocumento"
              autocomplete="off"
            ></v-text-field>
          </v-col>
          <!-- Número de documento -->
          <v-col
            cols="12"
            sm="6"
            md="3"
            class="py-0 my-0 d-flex justify-center"
          >
            <v-text-field
              type="text"
              outlined
              dense
              disabled
              filled
              readonly
              class="theme--light.v-input input"
              label="Número de documento"
              v-model="afiliado.documento"
              autocomplete="off"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <div>
        <v-card-text class="py-0 my-0">
          <v-row>
            <v-col cols="12">
              <v-data-table
                :headers="headersBeneficios"
                :items="listBeneficios"
                class="elevation-1 my-0 py-0"
                :hide-default-footer="true"
                :search="search"
                dense
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-row>
                      <v-col cols="6" align-self="center">
                        <v-text-field
                          v-model="search"
                          :append-icon="searchIcon"
                          label="Buscar"
                          single-line
                        ></v-text-field>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="4" class="mt-5">
                        <v-checkbox
                          v-model="fechaVigente"
                          v-bind:label="fechaVigenteText"
                          @change="loadBeneficios()"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="2" align-self="center">
                        <v-btn
                          color="primary"
                          @click="openModalEditBeneficio()"
                          class="to-right mt-1"
                        >
                          Nuevo beneficio
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        size="20"
                        v-on="on"
                        @click="openModalEditBeneficio(item)"
                      >
                        {{ editIcon }}
                      </v-icon>
                    </template>
                    <span>Editar beneficio</span>
                  </v-tooltip>
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        size="20"
                        v-on="on"
                        @click="deleteBeneficio(item)"
                      >
                        {{ deleteIcon }}
                      </v-icon>
                    </template>
                    <span>Eliminar beneficio</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="closeModal()"> Cerrar </v-btn>
      </v-card-actions>
      <v-dialog
        v-if="openModalEditBeneficioBan"
        v-model="openModalEditBeneficioBan"
        max-width="45%"
        @keydown.esc="closeModalEditBeneficio"
        persistent
      >
        <EditBeneficioAfiliado
          :beneficio="beneficio"
          :benId="benId"
          @closeModalEditBeneficio="closeModalEditBeneficio"
        ></EditBeneficioAfiliado>
      </v-dialog>
      <DeleteDialog
        :titleProp="titleDelete"
        :openDelete.sync="showDeleteModal"
        @onDeleteItem="confirmDelete()"
      />
    </v-container>
  </v-card>
</template>

<script>
import enums from "@/utils/enums/index.js";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import DeleteDialog from "@/components/shared/DeleteDialog";
import EditBeneficioAfiliado from "./EditBeneficioAfiliado.vue";

export default {
  components: { DeleteDialog, EditBeneficioAfiliado },
  name: "BeneficiosAfiliado",
  props: {
    afiliado: {
      type: Object,
      required: true
    },
    fechaConsultaAfi: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      title: enums.titles.BENEFICIOS_AFILIADO,
      titleDelete: enums.titles.DELETE_BENEFICIO_ANSES,
      enums: enums,
      editIcon: enums.icons.EDIT,
      deleteIcon: enums.icons.DELETE,
      searchIcon: enums.icons.SEARCH,
      headersBeneficios: [
        { text: "Beneficio", value: "beneficio", sortable: true },
        { text: "Tipo de beneficio", value: "tipoBeneficio", sortable: true },
        { text: "Fecha desde", value: "fechaDesde", sortable: false },
        { text: "Fecha hasta", value: "fechaHasta", sortable: false },
        { text: "Acciones", value: "actions", sortable: false, align: "end" }
      ],
      listBeneficios: [],
      fechaVigente: true,
      beneficioIDToDelete: null,
      fechaDesde: null,
      fechaHasta: null,
      beneficio: null,
      search: "",
      fechaVigenteText: null,
      rules: rules,
      openModalEditBeneficioBan: false,
      showDeleteModal: false,
      benId: null,
    };
  },
  async created() {
    this.loadBeneficios();
    this.fechaVigenteText =
      "Ver registros activos al " + this.formatDate(this.fechaConsultaAfi);
  },
  methods: {
    ...mapActions({
      fetchBeneficiosAfiliado: "afiliaciones/fetchBeneficiosAfiliado",
      deleteBeneficioAfiliado: "afiliaciones/deleteBeneficioAfiliado",
      setAlert: "user/setAlert"
    }),
    closeModal() {
      this.$emit("toggleModalBeneficiosAfiliado");
    },
    closeModalEditBeneficio() {
      this.openModalEditBeneficioBan = false;
      this.loadBeneficios();
    },
    async loadBeneficios() {
      let fecha = null;
      if (this.fechaVigente) fecha = this.fechaConsultaAfi;
      const response = await this.fetchBeneficiosAfiliado({
        benId: this.afiliado.id,
        fechaConsulta: fecha
      });
      this.listBeneficios = response;
    },
    openModalEditBeneficio(item) {
      this.beneficio = item;
      this.benId = this.afiliado.id;
      this.openModalEditBeneficioBan = true;
    },
    deleteBeneficio(beneficioToDelete) {
      this.showDeleteModal = true;
      this.beneficioIDToDelete = beneficioToDelete.beneficioId;
    },
    async confirmDelete() {
      const response = await this.deleteBeneficioAfiliado(
        this.beneficioIDToDelete
      );
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.loadBeneficios();
      }
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    }
  }
};
</script>

<style scoped>
::v-deep .theme--light.v-input input {
  color: rgb(56, 56, 56);
}

::v-deep .v-select__selection,
.v-select__selection--comma,
.v-select__selection--disabled input {
  color: black !important;
}

::v-deep .v-input__slot {
  cursor: context-menu !important;
}

::v-deep label {
  margin-bottom: 0;
}
</style>

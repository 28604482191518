<template>
  <v-card>
    <v-container>
      <v-card-title class="primary--text">
        {{ title }}
      </v-card-title>
      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveEdit()"
        >
          <v-row>
            <!-- Agente de cuenta -->
            <v-col cols="12" sm="12" md="12" class="py-0">
              <v-text-field
                v-model="agenteCuenta"
                label="Agente de cuenta"
                type="text"
                outlined
                dense
                readonly
                disabled
              >
              </v-text-field>
            </v-col>
            <!-- Tipo de alias -->
            <v-col cols="12" sm="12" md="12" class="py-0">
              <v-autocomplete
                v-model="tipoAliasSelected"
                :items="tiposAlias"
                label="Tipo de alias"
                item-text="value"
                :rules="rules.required"
                item-value="id"
                outlined
                dense
              >
              </v-autocomplete>
            </v-col>
            <!-- Alias -->
            <v-col cols="12" sm="12" md="12" class="py-0">
              <v-textarea
                auto-grow
                rows="1"
                outlined
                dense
                :rules="rules.required.concat([rules.requiredTrim(alias), rules.maxLength(alias, 100)])"
                label="Alias"
                v-model.trim="alias"
              ></v-textarea>
            </v-col>
            <!-- Fecha desde -->
            <v-col cols="12" sm="6" md="6" class="py-0">
              <v-menu
                ref="menu1"
                v-model="menuFechaDesde"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fechaDesdeSelected"
                    label="Fecha desde"
                    :append-icon="calendarIcon"
                    v-bind="attrs"
                    autocomplete="not"
                    hint="Formato DD/MM/AAAA"
                    @blur="fechaDesde = parseDateToIso(fechaDesdeSelected)"
                    outlined
                    dense
                    v-mask="'##/##/####'"
                    v-on="on"
                    :rules="
                      rules.required.concat(
                        new Date(parseDateToIso(fechaDesdeSelected)) <=
                          new Date(parseDateToIso(fechaHastaSelected))
                      ) || 'Formato incorrecto'
                    "
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaDesde"
                  no-title
                  @change="fechaDesdeSelected = formatDate(fechaDesde)"
                  @input="menuFechaDesde = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <!-- Fecha hasta -->
            <v-col cols="12" sm="6" md="6" class="py-0">
              <v-menu
                ref="menu2"
                v-model="menuFechaHasta"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fechaHastaSelected"
                    label="Fecha hasta"
                    :append-icon="calendarIcon"
                    hint="Formato DD/MM/AAAA"
                    @blur="fechaHasta = parseDateToIso(fechaHastaSelected)"
                    outlined
                    autocomplete="not"
                    dense
                    v-mask="'##/##/####'"
                    v-bind="attrs"
                    v-on="on"
                    :rules="
                      rules.required.concat(
                        new Date(parseDateToIso(fechaDesdeSelected)) <=
                          new Date(parseDateToIso(fechaHastaSelected))
                      ) || 'Formato incorrecto'
                    "
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaHasta"
                  no-title
                  @change="fechaHastaSelected = formatDate(fechaHasta)"
                  @input="menuFechaHasta = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="py-0">
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal()">
          Cancelar
        </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";

export default {
  name: "EditAliasAgente",
  props: {
    aliasAgente: { type: Object, required: false, default: null },
    agectaNom: { type: String, required: true, default: null },
    agectaId: { type: Number, required: true, default: null }
  },
  directives: { mask },
  mounted() {
    this.setSelects();
    if (this.aliasAgente !== null) {
      this.setAliasAgente();
    }
  },
  data() {
    return {
      title: "Nuevo alias del agente",
      tipoAliasSelected: null,
      tiposAlias: [],
      rules: rules,
      calendarIcon: enums.icons.CALENDAR,
      fechaHastaSelected: null,
      fechaDesdeSelected: null,
      menuFechaDesde: false,
      menuFechaHasta: false,
      alias: null,
      agenteCtaAliasId: null,
      isFormValid: false,
      agenteCuenta: `${this.agectaNom} - ${this.agectaId}`,
      fechaDesde: null,
      fechaHasta: null
    };
  },
  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
      saveAliasAgente: "agenteCuenta/saveAliasAgente",
      getTiposAlias: "agenteCuenta/getTiposAlias"
    }),
    async setSelects() {
      const tiposAlias = await this.getTiposAlias();
      this.tiposAlias = tiposAlias;
    },
    async setAliasAgente() {
      this.title = "Editar alias del agente";
      this.agenteCtaAliasId = this.aliasAgente.agentesCtaAliasId;
      this.alias = this.aliasAgente.alias;
      this.tipoAliasSelected = this.aliasAgente.tipoAliasId;
      this.fechaDesdeSelected = this.aliasAgente.vigenciaDesde;
      this.fechaDesde = this.parseDateToIso(this.aliasAgente.vigenciaDesde);
      this.fechaHastaSelected = this.aliasAgente.vigenciaHasta;
      this.fechaHasta = this.parseDateToIso(this.aliasAgente.vigenciaHasta);
    },
    async saveEdit() {
      const datosAlias = {
        agentesCtaAliasId: this.agenteCtaAliasId,
        agectaId: this.agectaId,
        alias: this.alias,
        tipoAliasId: this.tipoAliasSelected,
        vigenciaDesde: this.fechaDesde,
        vigenciaHasta: this.fechaHasta
      };
      const response = await this.saveAliasAgente(datosAlias);
      if (response.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito." });
        this.closeModal();
      }
    },
    closeModal() {
      this.$emit("toggleModalEditAlias");
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    }
  }
};
</script>

<style scoped>
::v-deep .theme--light.v-input input {
  color: rgb(56, 56, 56);
}
::v-deep .v-input__slot {
  cursor: context-menu !important;
}
</style>

<template>
  <v-card>
    <v-container>
      <v-card-title class="primary--text">
        {{ title }}
      </v-card-title>
      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveEdit()"
        >
          <v-row>
            <!-- Fecha -->
            <v-col cols="12" sm="12" md="6" class="py-0">
              <v-menu
                ref="menu1"
                v-model="menuFecha"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fechaSelected"
                    label="Fecha"
                    :append-icon="calendarIcon"
                    v-bind="attrs"
                    autocomplete="not"
                    hint="Formato DD/MM/AAAA"
                    @blur="fecha = parseDateToIso(fechaSelected)"
                    outlined
                    dense
                    v-mask="'##/##/####'"
                    v-on="on"
                    :rules="rules.required"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fecha"
                  no-title
                  @change="fechaSelected = formatDate(fecha)"
                  @input="menuFecha = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <!-- Tipo de movimiento -->
            <v-col cols="12" sm="12" md="6" class="py-0">
              <v-text-field
                v-model="tipoMovimiento"
                label="Tipo de movimiento"
                type="text"
                outlined
                dense
                readonly
                disabled
              >
              </v-text-field>
            </v-col>
            <!-- Dato anterior -->
            <v-col cols="12" sm="12" md="6" class="py-0">
              <v-text-field
                v-model="datoAnterior"
                label="Dato anterior"
                type="text"
                outlined
                dense
                readonly
                disabled
              >
              </v-text-field>
            </v-col>
            <!-- Dato nuevo -->
            <v-col cols="12" sm="12" md="6" class="py-0">
              <v-text-field
                v-model="datoNuevo"
                label="Dato nuevo"
                type="text"
                outlined
                dense
                readonly
                disabled
              >
              </v-text-field>
            </v-col>
            <!-- Observaciones -->
            <v-col cols="12" sm="12" md="12" class="py-0">
              <v-textarea
                auto-grow
                rows="1"
                outlined
                dense
                :rules="[rules.maxLength(observaciones, 100)]"
                label="Observaciones"
                v-model.trim="observaciones"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="py-0">
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal()"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";

export default {
  name: "EditMovimientoAfiliado",
  props: {
    movimientoAfiliado: { type: Object, required: true },
    benId: { type: Number, required: true },
  },
  directives: { mask },
  mounted() {
    this.setMovimientoAfiliado();
  },
  data() {
    return {
      title: "Editar movimiento",
      rules: rules,
      calendarIcon: enums.icons.CALENDAR,
      fechaSelected: null,
      menuFecha: false,
      observaciones: null,
      isFormValid: false,
      tipoMovimiento: `${this.movimientoAfiliado.tipoMovNombre}`,
      fecha: null,
      datoAnterior: null,
      datoNuevo: null,
    };
  },
  methods: {
    ...mapActions({
      saveMovimientoAfiliado: "configAfiliaciones/saveMovimientoAfiliado",
      setAlert: "user/setAlert",
    }),
    async setMovimientoAfiliado() {
      this.observaciones = this.movimientoAfiliado.observaciones;
      this.tipoMovimiento = this.movimientoAfiliado.tipoMovNombre;
      this.datoAnterior = this.movimientoAfiliado.datoAnterior;
      this.datoNuevo = this.movimientoAfiliado.datoNuevo;
      this.fechaSelected = this.movimientoAfiliado.fecha;
      this.fecha = this.parseDateToIso(this.movimientoAfiliado.fecha);
    },
    async saveEdit() {
      const datosMovimiento = {
        bcIds: this.movimientoAfiliado.bcIds,
        benId: this.benId,
        observaciones: this.observaciones,
        fecha: this.fecha,
      };
      const response = await this.saveMovimientoAfiliado(datosMovimiento);
      if (response.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito." });
        this.closeModal();
      }
    },
    closeModal() {
      this.$emit("closeAndReload");
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
  },
};
</script>

<style scoped>
::v-deep .theme--light.v-input input {
  color: rgb(56, 56, 56);
}
::v-deep .v-input__slot {
  cursor: context-menu !important;
}
</style>

<template>
  <v-card>
    <v-container>
      <v-card-title class="primary--text">
        {{ title }}
      </v-card-title>
      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveEdit()"
        >
          <v-row>
            <v-col cols="12" class="py-0">
              <v-card>
                <v-card-title>Datos generales</v-card-title>
                <v-card-text>
                  <v-row>
                    <!-- Codigo proveedor -->
                    <v-col cols="12" sm="6" md="6" class="py-0">
                      <v-text-field
                        type="text"
                        outlined
                        dense
                        :rules="
                          rules.required.concat([
                            rules.requiredTrim(codigoProv),
                            rules.maxLength(codigoProv, 10)
                          ])
                        "
                        label="Código (*)"
                        v-model.trim="codigoProv"
                        autocomplete="off"
                      ></v-text-field>
                    </v-col>
                    <!-- Nombre proveedor -->
                    <v-col cols="12" sm="6" md="6" class="py-0">
                      <v-text-field
                        type="text"
                        outlined
                        dense
                        :rules="
                          rules.required.concat([
                            rules.requiredTrim(nombreProv),
                            rules.maxLength(nombreProv, 50)
                          ])
                        "
                        label="Nombre/s y apellido/s (*)"
                        v-model.trim="nombreProv"
                        autocomplete="off"
                      ></v-text-field>
                    </v-col>
                    <!-- Nombre fantasía -->
                    <v-col cols="12" sm="6" md="6" class="py-0">
                      <v-text-field
                        type="text"
                        outlined
                        dense
                        :rules="[rules.maxLength(nombreFantasia, 100)]"
                        label="Nombre fantasía"
                        v-model="nombreFantasia"
                        autocomplete="off"
                      ></v-text-field>
                    </v-col>
                    <!-- Número de CUIT -->
                    <v-col cols="12" sm="6" md="6" class="py-0">
                      <v-text-field
                        type="text"
                        outlined
                        @change="validarCUIL(cuitOrCuil)"
                        dense
                        label="CUIT"
                        v-mask="'##-########-#'"
                        onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                        v-model="cuitOrCuil"
                        autocomplete="off"
                      ></v-text-field>
                    </v-col>
                    <!-- Condición IVA -->
                    <v-col cols="12" sm="6" md="6" class="py-0">
                      <v-select
                        v-model="tipoIvaSelected"
                        :items="tiposIva"
                        label="Condición de IVA (*)"
                        item-text="value"
                        :rules="rules.required"
                        item-value="id"
                        outlined
                        clearable
                        dense
                      >
                      </v-select>
                    </v-col>
                    <!-- Email -->
                    <v-col cols="12" sm="6" md="12" class="py-0">
                      <v-text-field
                        type="text"
                        outlined
                        dense
                        :rules="[rules.maxLength(emailProveedor, 100)]"
                        label="Email"
                        v-model="emailProveedor"
                        autocomplete="off"
                      ></v-text-field>
                    </v-col>
                    <!-- Calle -->
                    <v-col cols="12" sm="6" md="8" class="py-0">
                      <v-text-field
                        type="text"
                        outlined
                        dense
                        label="Calle"
                        v-model="calle"
                        :rules="[rules.maxLength(calle, 50)]"
                        autocomplete="off"
                      ></v-text-field>
                    </v-col>
                    <!-- Número domicilio -->
                    <v-col cols="12" sm="6" md="4" class="py-0">
                      <v-text-field
                        type="text"
                        outlined
                        dense
                        onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                        label="Número"
                        v-model="numDomicilio"
                        :rules="[rules.maxLength(numDomicilio, 20)]"
                        autocomplete="off"
                      ></v-text-field>
                    </v-col>
                    <!-- Barrio -->
                    <v-col cols="12" sm="6" md="6" class="py-0">
                      <v-select
                        v-model="barrioSelected"
                        :items="barrios"
                        label="Barrio"
                        item-text="value"
                        item-value="id"
                        outlined
                        clearable
                        dense
                      >
                      </v-select>
                    </v-col>
                    <!-- Localidad -->
                    <v-col cols="12" sm="6" md="6" class="py-0">
                      <v-select
                        v-model="localidadSelected"
                        :items="localidades"
                        label="Localidad"
                        item-text="value"
                        item-value="id"
                        outlined
                        clearable
                        dense
                      >
                      </v-select>
                    </v-col>
                    <!-- Codigo postal -->
                    <v-col cols="12" sm="6" md="6" class="py-0">
                      <v-text-field
                        type="text"
                        outlined
                        dense
                        v-mask="'#####'"
                        onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                        label="Código postal"
                        :rules="rules.validCodigoPostal"
                        v-model="cPostal"
                        autocomplete="off"
                      ></v-text-field>
                    </v-col>
                    <!-- Teléfono -->
                    <v-col cols="12" sm="6" md="6" class="py-0">
                      <v-text-field
                        type="text"
                        outlined
                        dense
                        label="Teléfono"
                        :rules="[rules.maxLength(telefono, 20)]"
                        onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                        v-model="telefono"
                        autocomplete="off"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card>
                <v-card-title> Datos de pagos </v-card-title>
                <v-card-text>
                  <v-row>
                    <!-- CBU -->
                    <v-col cols="12" sm="6" md="6" class="py-0">
                      <v-text-field
                        type="text"
                        outlined
                        dense
                        onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                        label="CBU"
                        v-mask="'######################'"
                        v-model="cbu"
                        autocomplete="off"
                      ></v-text-field>
                    </v-col>
                    <!-- a Orden -->
                    <v-col cols="12" sm="6" md="6" class="py-0">
                      <v-text-field
                        type="text"
                        outlined
                        :rules="[rules.maxLength(aOrden, 50)]"
                        dense
                        label="a Orden"
                        v-model="aOrden"
                        autocomplete="off"
                      ></v-text-field>
                    </v-col>
                    <!-- Descripcion mod. pago -->
                    <v-col class="pt-0 py-0">
                      <v-textarea
                        v-model="descripcionModPago"
                        class="pt-0 py-0"
                        auto-grow
                        :rules="[rules.maxLength(descripcionModPago, 100)]"
                        label="Descripción modalidad de pago"
                        rows="1"
                      ></v-textarea>
                    </v-col>
                    <!-- Mod. de pago -->
                    <v-col cols="12" sm="6" md="6" class="py-0">
                      <v-select
                        v-model="modPagoSelected"
                        :items="modalidadesDePago"
                        label="Modalidad de pago"
                        item-text="value"
                        item-value="id"
                        outlined
                        clearable
                        dense
                      >
                      </v-select>
                    </v-col>
                    <!-- Observaciones -->
                    <v-col cols="12" sm="6" md="12" class="pt-0 py-0">
                      <v-textarea
                        v-model="observaciones"
                        auto-grow
                        class="pt-0 py-0"
                        label="Observaciones"
                        :rules="[rules.maxLength(observaciones, 100)]"
                        rows="1"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cerrar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import { mask } from "vue-the-mask";
import rules from "@/utils/helpers/rules";
import validateCUIL from "@/utils/helpers/validateCUIL";

export default {
  name: "EditDatosProveedor",
  directives: { mask },
  created() {
    this.setSelects();
    this.setProveedorById();
  },
  props: {
    provId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      title: "Datos adicionales del proveedor",
      codigoProv: null,
      isFormValid: false,
      rules: rules,
      nombreProv: null,
      nombreFantasia: null,
      tipoIvaSelected: null,
      emailProveedor: null,
      tiposIva: [],
      calle: null,
      numDomicilio: null,
      barrioSelected: null,
      barrios: [],
      localidadSelected: null,
      localidades: [],
      cPostal: null,
      telefono: null,
      cbu: null,
      cuitOrCuil: null,
      aOrden: null,
      descripcionModPago: null,
      modPagoSelected: null,
      modalidadesDePago: [],
      observaciones: null
    };
  },
  methods: {
    ...mapActions({
      getTiposIvaCobradores: "configuracion/getTiposIvaCobradores",
      saveDatosAdicionalesProveedor:
        "afiliaciones/saveDatosAdicionalesProveedor",
      getBarrios: "afiliaciones/getBarrios",
      getModalidadesPagoProveedores:
        "afiliaciones/getModalidadesPagoProveedores",
      getLocalidades: "afiliaciones/getLocalidades",
      getProveedorTitById: "afiliaciones/getProveedorTitById",
      setAlert: "user/setAlert"
    }),
    async setSelects() {
      const tiposIva = await this.getTiposIvaCobradores();
      this.tiposIva = tiposIva;
      const localidades = await this.getLocalidades();
      this.localidades = localidades;
      const barrios = await this.getBarrios();
      this.barrios = barrios;
      const modalidadesPago = await this.getModalidadesPagoProveedores();
      this.modalidadesDePago = modalidadesPago;
    },
    async setProveedorById() {
      const response = await this.getProveedorTitById({ provId: this.provId });
      this.nombreProv = response.provNom;
      this.nombreFantasia = response.nombreFantasia;
      this.codigoProv = response.provCod;
      this.cuitOrCuil = response.cuit;
      this.emailProveedor = response.email;
      this.observaciones = response.observaciones;
      this.telefono = response.telefono;
      this.cPostal = response.codigoPostal;
      this.tipoIvaSelected = response.ivaId;
      this.barrioSelected = response.barId;
      this.localidadSelected = response.locId;
      this.numDomicilio = response.provNro;
      this.calle = response.calle;
    },
    async saveEdit() {
      const data = {
        provCod: this.codigoProv,
        provId: this.provId,
        provNom: this.nombreProv,
        nombreFantasia: this.nombreFantasia,
        cuit: this.cuitOrCuil
          ? this.cuitOrCuil.replaceAll("-", "")
          : this.cuitOrCuil,
        email: this.emailProveedor,
        observaciones: this.observaciones,
        telefono: this.telefono,
        codigoPostal: this.cPostal,
        ivaId: this.tipoIvaSelected,
        barId: this.barrioSelected,
        locId: this.localidadSelected,
        provNro: this.numDomicilio,
        calle: this.calle,
        provAOrden: this.aOrden,
        modalidadPago: this.descripcionModPago,
        tvIdModalidadPago: this.modPagoSelected,
        cBU: this.cbu
      };
      const response = await this.saveDatosAdicionalesProveedor(data);
      if (response.status === 200) {
        this.setAlert({ type: "success", message: "Generado con éxito." });
        this.closeModal();
      }
    },
    closeModal() {
      this.$emit("closeAndReload");
      this.$emit("nextQuestion");
    },
    validarCUIL(cuil) {
      const res = validateCUIL.validateCUITCUIL(cuil);
      if (res === false) {
        this.setAlert({
          type: "warning",
          message: "El CUIL ingresado es incorrecto."
        });
        this.isFormValid = false;
      }
    }
  }
};
</script>

<style></style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',[_c('v-card-title',[_c('span',{staticClass:"pl-1 primary--text"},[_vm._v(_vm._s(_vm.formEditTitle)+" ")])]),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"form":"form","id":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.saveGerenciadoraGestionAfiliado()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.itemsGerenciadoras,"label":"Gerenciadora","item-text":"value","item-value":"id","outlined":"","clearable":"","required":"","rules":_vm.rules.required,"dense":""},model:{value:(_vm.gerenciadoraNombre),callback:function ($$v) {_vm.gerenciadoraNombre=$$v},expression:"gerenciadoraNombre"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"6","md":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.itemsNiveles,"label":"Nivel","item-text":"value","item-value":"id","outlined":"","clearable":"","required":"","rules":_vm.rules.required,"dense":""},model:{value:(_vm.nivelNombre),callback:function ($$v) {_vm.nivelNombre=$$v},expression:"nivelNombre"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Fecha desde","append-icon":_vm.calendarIcon,"hint":"formato DD/MM/AAAA","onkeypress":"return (event.charCode >= 47 && event.charCode <= 57)","clearable":"","dense":"","outlined":"","autocomplete":"off","rules":_vm.rules.required.concat(
                      _vm.rules.validDate,
                      new Date(_vm.parseDateToIso(_vm.fechaDesdeTextField)) <=
                        new Date(_vm.parseDateToIso(_vm.fechaHastaTextField)) ||
                        'Formato incorrecto'
                    )},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }_vm.menuDesde = false},"blur":function($event){[(_vm.fechaDesde = _vm.parseDateToIso(_vm.fechaDesdeTextField))]},"change":function($event){[(_vm.fechaDesde = _vm.parseDateToIso(_vm.fechaDesdeTextField))]}},model:{value:(_vm.fechaDesdeTextField),callback:function ($$v) {_vm.fechaDesdeTextField=$$v},expression:"fechaDesdeTextField"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDesde),callback:function ($$v) {_vm.menuDesde=$$v},expression:"menuDesde"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"change":function($event){_vm.fechaDesdeTextField = _vm.formatDate(_vm.fechaDesde)},"input":function($event){_vm.menuDesde = false}},model:{value:(_vm.fechaDesde),callback:function ($$v) {_vm.fechaDesde=$$v},expression:"fechaDesde"}})],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('v-menu',{ref:"menu2",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Fecha hasta","append-icon":_vm.calendarIcon,"onkeypress":"return (event.charCode >= 47 && event.charCode <= 57)","hint":"formato DD/MM/AAAA","clearable":"","dense":"","rules":_vm.rules.validDate.concat(
                      new Date(_vm.parseDateToIso(_vm.fechaDesdeTextField)) <=
                        new Date(_vm.parseDateToIso(_vm.fechaHastaTextField)) ||
                        'Formato incorrecto'
                    ),"outlined":"","autocomplete":"off"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }_vm.menuHasta = false},"blur":function($event){[(_vm.fechaHasta = _vm.parseDateToIso(_vm.fechaHastaTextField))]},"change":function($event){[(_vm.fechaHasta = _vm.parseDateToIso(_vm.fechaHastaTextField))]}},model:{value:(_vm.fechaHastaTextField),callback:function ($$v) {_vm.fechaHastaTextField=$$v},expression:"fechaHastaTextField"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuHasta),callback:function ($$v) {_vm.menuHasta=$$v},expression:"menuHasta"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"change":function($event){_vm.fechaHastaTextField = _vm.formatDate(_vm.fechaHasta)},"input":function($event){_vm.menuHasta = false}},model:{value:(_vm.fechaHasta),callback:function ($$v) {_vm.fechaHasta=$$v},expression:"fechaHasta"}})],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 py-0",attrs:{"cols":"12","sm":"6","md":"12"}},[_c('v-textarea',{attrs:{"auto-grow":"","dense":"","rules":[_vm.rules.maxLength(_vm.observaciones, 250)],"outlined":"","label":"Observaciones","rows":"1"},model:{value:(_vm.observaciones),callback:function ($$v) {_vm.observaciones=$$v},expression:"observaciones"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.closeModal}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"type":"submit","disabled":!_vm.isFormValid,"form":"form","color":"primary"}},[_vm._v(" Guardar ")])],1),_c('span')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
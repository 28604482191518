<template>
  <v-card flat>
    <v-container fluid>
      <v-data-table
        :headers="headers"
        item-key="aporteEsperadoId"
        hide-default-footer
        :items="detalleAportes"
        class="elevation-1"
        show-expand
        :expanded.sync="expanded"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-row>
              <v-col cols="12" md="10" align="start">
                <v-card-title class="pl-1 primary--text"
                  >Movimientos en importes esperados para el grupo
                  familiar</v-card-title
                >
              </v-col>
              <v-col cols="12" md="2" align="end" class="pt-7">
                <v-btn
                  color="primary"
                  @click="toggleModalEditMovAporteEsperado()"
                  class="to-right"
                >
                  Nuevo
                </v-btn>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                @click="toggleModalEditMovAporteEsperado(item.aporteEsperadoId)"
              >
                {{ editIcon }}
              </v-icon>
            </template>
            <span>Editar movimiento</span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                @click="deleteMovimiento(item.aporteEsperadoId)"
              >
                {{ deleteIcon }}
              </v-icon>
            </template>
            <span>Eliminar movimiento</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.data-table-expand`]="{ item, isExpanded }">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                @click="handleExpansion(item, isExpanded)"
                v-bind="attrs"
                size="20"
                v-on="on"
                >{{ isExpanded ? "mdi-account-check" : "mdi-account" }}</v-icon
              >
            </template>
            <span>Usuarios</span>
          </v-tooltip>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <strong>Fecha alta:</strong>
            {{ item.fechaAlta }}
            <strong style="padding-left: 30px">Usuario alta:</strong>
            {{ item.usuAlta }}
            <strong style="padding-left: 30px">Fecha modificación:</strong>
            {{ item.fechaModi }}
            <strong style="padding-left: 30px">Usuario modificación:</strong>
            {{ item.usuModi }}
          </td>
        </template>
      </v-data-table>
      <v-dialog
        v-if="modalEditMovAporteEsperado"
        v-model="modalEditMovAporteEsperado"
        max-width="50%"
        @keydown.esc="toggleModalEditMovAporteEsperado()"
        persistent
      >
        <EditMovimientoAporteEsperado
          @toggleModalEditMovAporteEsperado="toggleModalEditMovAporteEsperado"
          :aporteEsperadoId="aporteEsperadoId"
          :topeImporte="topeImporte"
          :benGrId="benGrId"
        ></EditMovimientoAporteEsperado>
      </v-dialog>
    </v-container>
    <v-container class="pt-0 mt-0">
      <v-row>
        <v-col cols="12" align="end">
          <v-btn color="primary" class="to-right" @click="closeModal">
            Cerrar
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <DeleteDialog
      :titleProp="titleDelete"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-card>
</template>

<script>
import DeleteDialog from "@/components/shared/DeleteDialog";
import enums from "@/utils/enums/index.js";
import EditMovimientoAporteEsperado from "./EditMovimientoAporteEsperado.vue";
import { mapActions } from "vuex";
// import PageHeader from "@/components/ui/PageHeader";

export default {
  components: {
    DeleteDialog,
    EditMovimientoAporteEsperado
    // PageHeader
  },
  name: "EditAportesEsperados",
  props: { benGrId: { type: Number, required: false, default: null } },

  data() {
    return {
      title: "Aportes esperados del grupo familiar",
      modalAportesEsp: false,
      deleteIcon: enums.icons.DELETE,
      editIcon: enums.icons.EDIT,
      aporteEsperadoId: null,
      headers: [
        {
          text: "Período desde",
          align: "start",
          sortable: false,
          value: "periodoDesde"
        },
        {
          text: "Período hasta",
          sortable: false,
          value: "periodoHasta"
        },
        {
          text: "Importe",
          sortable: false,
          value: "importe",
          align: "end"
        },
        { text: "Acciones", value: "actions", sortable: false, align: "end" },
        { text: "", value: "data-table-expand", align: "end" }
      ],
      detalleAportes: [],
      titleDelete: "¿Eliminar detalle?",
      idToDelete: null,
      showDeleteModal: false,
      modalEditMovAporteEsperado: false,
      expanded: [],
      topeImporte: null
    };
  },
  created() {
    this.setAportesEsperados();
  },
  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
      getAportesEsperados: "afiliaciones/getAportesEsperados",
      getTopeImporteAportesEsperados:
        "afiliaciones/getTopeImporteAportesEsperados",
      getAporteEsperadoById: "afiliaciones/getAporteEsperadoById",
      deleteAporteEsperado: "afiliaciones/deleteAporteEsperado"
    }),
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
    async setAportesEsperados() {
      const aportesEsperados = await this.getAportesEsperados(this.benGrId);
      this.detalleAportes = aportesEsperados;
      const topeImporte = await this.getTopeImporteAportesEsperados();
      this.topeImporte = topeImporte;
    },
    deleteMovimiento(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    async confirmDelete() {
      const response = await this.deleteAporteEsperado(this.idToDelete);
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.setAportesEsperados();
      }
    },
    closeModal() {
      this.$emit("toggleModalAportesEsperadosAfiliado");
    },
    toggleModalEditMovAporteEsperado(id) {
      this.modalEditMovAporteEsperado = !this.modalEditMovAporteEsperado;
      this.aporteEsperadoId = id;
      this.setAportesEsperados();
    }
  }
};
</script>

<style></style>

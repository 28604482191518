<template>
  <v-card>
    <v-container>
      <v-data-table
        :headers="headers"
        :items="items"
        class="elevation-1"
        item-key="bcId"
        :loading="loading"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-row>
              <v-col cols="10">
                <v-card-title class="pl-1 primary--text"
                  >{{ title }}
                </v-card-title>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
        <template v-slot:[`item.liquidado`]="{ item }">
          <v-icon v-if="item.liquidado" small color="primary">
            {{ checkIcon }}
          </v-icon>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                @click="
                  item.liquidado
                    ? toggleModalVerLiquidacionesMovs(item)
                    : item.liquidadoAnteriormente
                    ? linkOrUnlinkLiquidacion(item)
                    : true
                "
              >
                {{
                  item.liquidado
                    ? seeIcon
                    : item.liquidadoAnteriormente
                    ? vincularIcon
                    : infoIcon
                }}
              </v-icon>
            </template>
            <span v-if="item.liquidado">Ver liquidaciones</span>
            <span v-else-if="item.liquidadoAnteriormente">
              Vincular liquidación
            </span>
            <span v-else
              >No es posible vincular este movimiento a una liquidación</span
            >
          </v-tooltip>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal()"> Cerrar </v-btn>
      </v-card-actions>
    </v-container>
    <v-dialog
      v-if="modalVerLiquidacionesMovs"
      v-model="modalVerLiquidacionesMovs"
      max-width="70%"
      @keydown.esc="toggleModalVerLiquidacionesMovs"
      persistent
    >
      <VerLiquidacionesMovimientos
        :benId="benId"
        :movItem="movItem"
        @closeModal="toggleModalVerLiquidacionesMovs"
      ></VerLiquidacionesMovimientos>
    </v-dialog>
    <DeleteDialog
      :titleProp="titleDelete"
      :maxWidth="'25%'"
      :textDelete="textDelete"
      :isLoading="btnDeleteIsLoading"
      :confirmButtonText="textBtnDelete"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import VerLiquidacionesMovimientos from "@/components/modules/afiliaciones/afiliados/VerLiquidacionesMovimientos.vue";
import DeleteDialog from "@/components/shared/DeleteDialog";

export default {
  name: "LiquidacionesMovimientosAfiliado",
  components: { VerLiquidacionesMovimientos, DeleteDialog },
  props: {
    benId: {
      type: Number,
      required: true
    },
    afiliadoNom: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      title: `Liquidaciones de movimientos del afiliado: ${this.afiliadoNom}`,
      checkIcon: enums.icons.CHECK_OUTLINE,
      vincularIcon: enums.icons.LINK_PLUS,
      infoIcon: enums.icons.SNB_INFO,
      seeIcon: enums.icons.SEE,
      showDeleteModal: false,
      idToSend: null,
      items: [],
      headers: [
        {
          text: "Movimiento",
          value: "movNom",
          sortable: false
        },
        {
          text: "Liquidado",
          value: "liquidado",
          align: "center",
          sortable: false
        },
        { text: "Acciones", value: "actions", sortable: false, align: "end" }
      ],
      loading: false,
      modalVerLiquidacionesMovs: false,
      movItem: {},
      titleDelete: "",
      textDelete: "",
      textBtnDelete: "",
      btnDeleteIsLoading: false
    };
  },
  created() {
    this.getLiquidacionesMovsByAfiliado();
  },
  methods: {
    ...mapActions({
      getLiquidacionesMovimientosByBenId:
        "configAfiliaciones/getLiquidacionesMovimientosByBenId",
      vincularLiquidacionMovAfiliado:
        "configAfiliaciones/vincularLiquidacionMovAfiliado",
      setAlert: "user/setAlert"
    }),
    closeModal() {
      this.$emit("closeModal");
    },
    async getLiquidacionesMovsByAfiliado() {
      this.loading = true;
      try {
        const response = await this.getLiquidacionesMovimientosByBenId(
          this.benId
        );
        if (response.status === 200) this.items = response.data.data;
      } catch (error) {}
      this.loading = false;
    },
    async linkOrUnlinkLiquidacion(item) {
      this.titleDelete = "¿Vincular liquidación?";
      this.textBtnDelete = "Vincular";
      this.textDelete =
        "Todas las liquidaciones desvinculadas volverán a ser vinculadas al movimiento seleccionado.";
      this.idToSend = item.bcId;
      this.showDeleteModal = true;
    },
    async confirmDelete() {
      this.btnDeleteIsLoading = true;
      try {
        const response = await this.vincularLiquidacionMovAfiliado(
          this.idToSend
        );
        if (response.status === 200) {
          this.setAlert({
            type: "success",
            message: "Liquidación vinculada con éxito."
          });
          this.getLiquidacionesMovsByAfiliado();
          this.showDeleteModal = false;
        }
      } catch (error) {}
      this.btnDeleteIsLoading = false;
    },
    toggleModalVerLiquidacionesMovs(item) {
      this.modalVerLiquidacionesMovs = !this.modalVerLiquidacionesMovs;
      this.movItem = item;
      this.getLiquidacionesMovsByAfiliado();
    }
  }
};
</script>

<style></style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',{staticClass:"pb-0"},[_c('v-card-title',{staticClass:"pt-0"},[_c('span',{staticClass:"pl-1 primary--text"},[_vm._v(_vm._s(this.formEditTitle))])]),_c('v-card-text',{staticClass:"pb-0"},[_c('v-form',{ref:"form",attrs:{"form":"form","id":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.saveMovAporteEsperado()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"pb-0 mt-0",attrs:{"cols":"12","md":"4","sm":"12"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('######'),expression:"'######'"}],attrs:{"dense":"","outlined":"","label":"Período desde","hint":"Formato AAAAMM","persistent-hint":"","type":"text","onkeypress":"return (event.charCode >= 48 && event.charCode <= 57)","rules":_vm.rules.required.concat(
                  [_vm.rules.validDateRange(_vm.periodoDesde, _vm.periodoHasta)],
                  _vm.rules.periodoYyyyMm
                )},model:{value:(_vm.periodoDesde),callback:function ($$v) {_vm.periodoDesde=$$v},expression:"periodoDesde"}})],1),_c('v-col',{staticClass:"pb-0 mt-0",attrs:{"cols":"12","md":"4","sm":"12"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('######'),expression:"'######'"}],attrs:{"dense":"","outlined":"","label":"Período hasta","type":"text","hint":"Formato AAAAMM","onkeypress":"return (event.charCode >= 48 && event.charCode <= 57)","persistent-hint":"","rules":_vm.rules.required.concat(
                  [_vm.rules.validDateRange(_vm.periodoDesde, _vm.periodoHasta)],
                  _vm.rules.periodoYyyyMm
                )},model:{value:(_vm.periodoHasta),callback:function ($$v) {_vm.periodoHasta=$$v},expression:"periodoHasta"}})],1),_c('v-col',{staticClass:"pb-0 mt-0",attrs:{"cols":"12","md":"4  ","sm":"12"}},[_c('currency-input',{staticClass:"right-input",attrs:{"label":"Importe","oulined":"","dense":"false","outlined":"false","options":_vm.currencyOptions,"rules":_vm.topeImporte != null
                  ? _vm.rules.required.concat(
                      !(_vm.importe > _vm.topeImporte) ||
                        'El importe ingresado supera el tope configurado.',
                      [_vm.rules.maxLength(_vm.importe, 18)]
                    )
                  : []},model:{value:(_vm.importe),callback:function ($$v) {_vm.importe=$$v},expression:"importe"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"type":"submit","disabled":!_vm.isFormValid,"form":"form","color":"primary"}},[_vm._v(" Guardar ")])],1),(this.modalSaveAndCloseVigencia === true)?_c('v-dialog',{attrs:{"max-width":"40.0rem"},model:{value:(_vm.modalSaveAndCloseVigencia),callback:function ($$v) {_vm.modalSaveAndCloseVigencia=$$v},expression:"modalSaveAndCloseVigencia"}},[_c('v-card',[_c('v-container',[_c('v-card-text',[_c('v-card-title',{staticClass:"headline primary--text"},[_vm._v(" Existe una vigencia entre los períodos ingresados. ¿Desea cerrar vigencia y crear la nueva? ")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.closeModalSaveAndCloseVigencia()}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"text":"","color":"primary","dark":""},on:{"click":function($event){return _vm.confirmSaveAndCloseVigencia()}}},[_vm._v(" Confirmar ")])],1)],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }} </span>
      </v-card-title>
      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveMovimientoEmpresa()"
        >
          <v-row>
            <v-col cols="12" md="6" class="py-0">
              <!-- Fecha de vigencia desde -->
              <v-menu
                ref="menu"
                v-model="menuDesde"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fechaDesdeTextField"
                    label="Vigencia desde"
                    :append-icon="calendarIcon"
                    v-mask="'##/##/####'"
                    hint="formato DD/MM/AAAA"
                    @keydown.tab="menuDesde = false"
                    onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                    @blur="[(fechaDesde = parseDateToIso(fechaDesdeTextField))]"
                    @change="
                      [(fechaDesde = parseDateToIso(fechaDesdeTextField))]
                    "
                    clearable
                    dense
                    outlined
                    autocomplete="off"
                    :rules="
                      rules.required.concat(
                        rules.validDate,
                        new Date(parseDateToIso(fechaDesdeTextField)) <=
                          new Date(parseDateToIso(fechaHastaTextField)) ||
                          'Formato incorrecto'
                      )
                    "
                    v-bind="attrs"
                    v-on="on"
                  >
                  </v-text-field>
                </template>

                <v-date-picker
                  v-model="fechaDesde"
                  no-title
                  scrollable
                  @change="fechaDesdeTextField = formatDate(fechaDesde)"
                  @input="menuDesde = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <!-- Fecha de vigencia hasta -->

              <v-menu
                ref="menu2"
                v-model="menuHasta"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fechaHastaTextField"
                    label="Vigencia hasta"
                    :append-icon="calendarIcon"
                    @keydown.tab="menuHasta = false"
                    v-mask="'##/##/####'"
                    onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                    hint="formato DD/MM/AAAA"
                    @blur="[(fechaHasta = parseDateToIso(fechaHastaTextField))]"
                    @change="
                      [(fechaHasta = parseDateToIso(fechaHastaTextField))]
                    "
                    clearable
                    dense
                    :rules="
                      rules.validDate.concat(
                        new Date(parseDateToIso(fechaDesdeTextField)) <=
                          new Date(parseDateToIso(fechaHastaTextField)) ||
                          'Formato incorrecto'
                      )
                    "
                    outlined
                    autocomplete="off"
                    v-bind="attrs"
                    v-on="on"
                  >
                  </v-text-field>
                </template>

                <v-date-picker
                  v-model="fechaHasta"
                  no-title
                  scrollable
                  @change="fechaHastaTextField = formatDate(fechaHasta)"
                  @input="menuHasta = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <!-- Empresa -->
            <v-col cols="12" md="11" class="py-0">
              <v-autocomplete
                ref="empresa-autocomplete"
                v-model="empresaSelected"
                :items="empresas"
                :search-input.sync="empresaAutocompleteInput"
                item-text="value"
                item-value="id"
                :filter="customFilter"
                :rules="rules.required"
                @change="setSucursalesYCategorias()"
                outlined
                clearable
                dense
                autocomplete="off"
                label="Empresa"
                hint="Nombre o CUIT"
                persistent-hint
              >
                <template slot="item" slot-scope="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.value }}</v-list-item-title>
                    <v-list-item-subtitle
                      >CUIT: {{ item.cuit }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
                <template v-slot:append>
                  <v-progress-circular
                    indeterminate
                    size="28"
                    v-if="empresasLoading"
                    color="primary"
                  ></v-progress-circular>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col md="1" class="pt-2 ml-0">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click="toggleModalNuevaEmpresa()"
                    v-on="on"
                    v-bind="attrs"
                  >
                    {{ addIcon }}
                  </v-icon>
                </template>
                <span>Crear empresa</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row>
            <!-- Sucursal -->
            <v-col
              cols="12"
              :md="parametrosGenerales ? 6 : 12"
              v-if="sucursales.length > 0"
              class="py-0"
            >
              <v-autocomplete
                v-model="sucursalSelected"
                :items="sucursales"
                item-text="value"
                item-value="id"
                label="Sucursal"
                dense
                clearable
                outlined
              ></v-autocomplete>
            </v-col>

            <!-- Categoria -->
            <v-col :md="sucursales.length > 0 ? 6 : 12" cols="12" class="py-0">
              <v-autocomplete
                id="categoriaAutocomplete"
                v-model="categoriaSelected"
                :items="categorias"
                item-text="value"
                item-value="id"
                label="Categoría"
                dense
                clearable
                outlined
              ></v-autocomplete>
            </v-col>
          </v-row>
          <!--Sueldo -->

          <v-row>
            <v-col cols="12" md="4" class="py-0">
              <currency-input
                v-model="sueldo"
                label="Sueldo"
                :options="currencyOptions"
                :rules="sueldo === 0 ? [] : rules.required"
              ></currency-input>
            </v-col>
            <!-- Aporte -->
            <v-col cols="12" md="4" class="py-0">
              <currency-input
                v-model="aporte"
                :options="currencyOptions"
                label="Aporte"
                :rules="aporte === 0 ? [] : rules.required"
              ></currency-input>
            </v-col>
            <!-- Contribución -->
            <v-col cols="12" md="4" class="py-0">
              <currency-input
                v-model="contribucion"
                :options="currencyOptions"
                label="Contribución"
                :rules="contribucion === 0 ? [] : rules.required"
              ></currency-input>
            </v-col>
          </v-row>
          <!-- Observaciones -->
          <v-row>
            <v-col
              cols="12"
              md="4"
              sm="12"
              class="py-0"
              v-if="showSwitchEmpDefecto"
            >
              <v-switch
                label="Empresa por defecto"
                dense
                outlined
                v-model="empDefecto"
              >
              </v-switch>
            </v-col>
            <v-col cols="12" :md="showSwitchEmpDefecto ? 8 : 12" class="py-0">
              <v-textarea
                v-model="observaciones"
                label="Observaciones"
                dense
                rows="2"
                auto-grow
                outlined
                :rules="
                  observaciones !== null
                    ? [rules.maxLength(observaciones, 254)]
                    : []
                "
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid || empresasLoading"
          form="form"
          color="primary"
          @click.stop.prevent="saveMovimientoEmpresa()"
        >
          Guardar
        </v-btn>
      </v-card-actions>
      <span> </span>
      <v-dialog
        v-if="openModalNuevaEmpresa"
        v-model="openModalNuevaEmpresa"
        max-width="45%"
        @keydown.esc="toggleModalNuevaEmpresa"
        persistent
      >
        <NuevaEmpresaAfiliado
          @toggleModalNuevaEmpresa="toggleModalNuevaEmpresa"
          :newEmpresaMonotributista="newEmpresaMonotributista"
          @empresaCreada="empresaAutocompleteInput = $event"
          @cuilEmpresaCreada="cuil = $event"
          :cargaEmpleadorModal="cargaEmpleadorModal"
        >
        </NuevaEmpresaAfiliado>
      </v-dialog>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import NuevaEmpresaAfiliado from "./NuevaEmpresaAfiliado.vue";
import { mask } from "vue-the-mask";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";

export default {
  name: "EditMovimientoEmpresaAfiliado",
  components: {
    NuevaEmpresaAfiliado,
    CurrencyInput
  },
  props: {
    benefEmpId: { type: Number, required: false, default: null },
    benId: { type: Number, required: false, default: null },
    empId: { type: Number, required: false, default: null },
    newEmpresaMonotributista: { type: Object, required: false, default: null },
    cargaEmpleadorModal: { type: Boolean, required: false, default: false },
    fechaConsulta: { type: String, required: false, default: null }
  },

  directives: { mask },
  data: vm => ({
    isFormValid: false,
    formEditTitle: "Editar movimiento en empresa",
    title: "Movimientos en empresas",
    calendarIcon: enums.icons.CALENDAR,
    addIcon: enums.icons.CIRCLE_ADD_ITEM,
    rules: rules,
    banderaEmpresaSelected: false,
    openModalNuevaEmpresa: false,
    sueldo: 0,
    aporte: 0,
    contribucion: 0,
    cuil: null,
    empDefecto: false,
    showSwitchEmpDefecto: false,
    observaciones: null,
    menuDesde: null,
    fechaDesde: null,
    fechaDesdeTextField: null,
    menuHasta: null,
    fechaHasta: "2199-01-01",
    fechaHastaTextField: vm.formatDate("2199-01-01"),
    empresaAutocompleteInput: null,
    empresasLoading: null,
    empresas: [],
    empresaSelected: null,
    sucursales: [],
    sucursalSelected: null,
    categorias: [],
    categoriaSelected: null,

    parametrosGenerales: {},
    currencyOptions: {
      locale: "nl-NL",
      currency: "USD",
      currencyDisplay: "narrowSymbol",
      valueRange: {
        min: 0,
        max: 999999999999999
      },
      precision: undefined,
      hideCurrencySymbolOnFocus: true,
      hideGroupingSeparatorOnFocus: true,
      hideNegligibleDecimalDigitsOnFocus: true,
      autoDecimalDigits: false,
      exportValueAsInteger: false,
      autoSign: true,
      useGrouping: true
    }
  }),
  created() {
    this.setParametrosGenerales();
    this.setSucursalesYCategorias();

    if (this.benefEmpId != null) {
      this.banderaEmpresaSelected = true;
      this.showSwitchEmpDefecto = true;
      this.empresasLoading = true;
      this.setMovimientoEmpresa(this.benefEmpId);
    } else {
      this.newMovimientoEmpresa();
      if (this.newEmpresaMonotributista !== null && !this.cargaEmpleadorModal) {
        this.toggleModalNuevaEmpresa();
      }
    }
  },

  watch: {
    empresaAutocompleteInput(val) {
      if (val && val.length >= 3) {
        this.getEmpresasFilter();
      }
    }
  },
  methods: {
    ...mapActions({
      saveEmpresasAfiliado: "afiliaciones/saveEmpresasAfiliado",
      fetchEmpresasByQuery: "aportes/fetchEmpresasByQuery",
      getSucursalesByEmpresa: "afiliaciones/getSucursalesByEmpresa",
      getCategoriasByEmpresa: "afiliaciones/getCategoriasByEmpresa",
      getEmpresasAfiliadoById: "afiliaciones/getEmpresasAfiliadoById",
      getParametrosGeneralesEmpresas:
        "afiliaciones/getParametrosGeneralesEmpresas",
      setAlert: "user/setAlert"
    }),
    newMovimientoEmpresa() {
      this.formEditTitle = "Nuevo movimiento de empresa";
      if (this.fechaConsulta === null) {
        this.getTodaysDate();
      } else {
        this.fechaDesde = this.fechaConsulta;
        this.fechaDesdeTextField = this.formatDate(this.fechaDesde);
      }
    },
    async setParametrosGenerales() {
      const response = await this.getParametrosGeneralesEmpresas();
      this.parametrosGenerales = response.habilitarCategoriaSindEnEmpresa;
    },
    async setMovimientoEmpresa() {
      const response = await this.getEmpresasAfiliadoById(this.benefEmpId);
      this.benId = response.benId;
      this.benefEmpId = response.benefEmpId;
      this.empresaAutocompleteInput = response.empNom;
      this.cuil = response.cuil;
      this.sueldo = response.sueldo;
      this.observaciones = response.observaciones;
      this.aporte = response.aporte;
      this.contribucion = response.contribucion;
      this.sucursalSelected = response.sucId;
      this.categoriaSelected = response.catSindId;
      this.empDefecto = response.empDefecto;
      this.fechaDesdeTextField = response.fechaDesde;
      this.fechaDesde = this.parseDateToIso(response.fechaDesde);
      this.fechaHastaTextField = response.fechaHasta;
      this.fechaHasta = this.parseDateToIso(response.fechaHasta);
    },
    async saveMovimientoEmpresa() {
      const data = {
        benId: this.benId,
        benefEmpId: this.benefEmpId,
        empId: this.empresaSelected,
        sueldo: this.sueldo,
        observaciones: this.observaciones,
        aporte: this.aporte,
        contribucion: this.contribucion,
        sucId: this.sucursales.length > 0 ? this.sucursalSelected : null,
        catSinId: this.categoriaSelected,
        empDefecto: this.empDefecto,
        fechaDesde: this.fechaDesde,
        fechaHasta: this.fechaHasta
      };
      const res = await this.saveEmpresasAfiliado(data);
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito" });
        this.closeModal();
        this.$emit("nextQuestion");
      }
    },
    closeModal() {
      this.$emit("closeAndReload");
    },
    getTodaysDate() {
      let today = new Date();
      let año = today.getFullYear();
      let mes = today.getMonth() + 1;
      let dia = today.getDate();
      dia < 10 ? (dia = `0${dia}`) : dia;
      mes < 10 ? (mes = `0${mes}`) : mes;
      this.fechaDesde = `${año}-${mes}-${dia}`;
      this.fechaDesdeTextField = this.formatDate(this.fechaDesde);
    },
    // metodos date picker
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    async setSucursalesYCategorias() {
      if (this.empresaSelected !== null && this.empresaSelected !== undefined) {
        const sucursal = await this.getSucursalesByEmpresa({
          empId: this.empresaSelected
        });
        this.sucursales = sucursal;
        const categoria = await this.getCategoriasByEmpresa({
          empId: this.empresaSelected
        });
        this.categorias = categoria;
      } else {
        return;
      }
      if (this.categorias.length === 1) {
        this.categoriaSelected = this.categorias[0].id;
      }
    },
    //  metodos autocomplete empresas
    getEmpresasFilter() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(async () => {
        if (
          this.empresas?.some(
            x => x.nombreCuit == this.empresaAutocompleteInput
          )
        ) {
          return;
        } else if (!this.banderaEmpresaSelected) {
          this.empresasLoading = true;
          const res = await this.fetchEmpresasByQuery({
            input: this.empresaAutocompleteInput
          });
          this.$refs["empresa-autocomplete"].cachedItems = [];
          this.empresas = res;
          this.empresasLoading = false;
        } else {
          this.empresasLoading = true;
          const response = await this.fetchEmpresasByQuery({
            input: this.cuil
          });
          this.empresas = response;
          this.empresaSelected = response[0].id;
          // ^^ evita que se borre el campo empresaAutocompleteInput al setear los datos
          this.setSucursalesYCategorias();
          this.banderaEmpresaSelected = false;
          this.empresasLoading = false;
        }
      }, 800);
    },
    customFilter(item, queryText, itemText) {
      return (
        item.nombreCuit ||
        item.cuit.indexOf(queryText) > -1 ||
        item.value.indexOf(queryText) > -1
      );
    },
    toggleModalNuevaEmpresa() {
      if (this.openModalNuevaEmpresa === true) {
        this.openModalNuevaEmpresa = !this.openModalNuevaEmpresa;
      } else {
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        this.timer = setTimeout(() => {
          this.openModalNuevaEmpresa = !this.openModalNuevaEmpresa;
          this.banderaEmpresaSelected = true;
        }, 10);
      }
    }
  }
};
</script>

<style></style>

<template>
  <v-card>
    <v-container>
      <v-card-title class="primary--text">
        {{ title }}
      </v-card-title>
      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveEdit()"
        >
          <v-row>
            <!-- Agente de cuenta -->
            <v-col cols="12" sm="12" md="12" class="py-0">
              <v-text-field
                v-model="agenteCuenta"
                label="Agente de cuenta"
                type="text"
                outlined
                dense
                readonly
                disabled
              >
              </v-text-field>
            </v-col>
            <!-- Condición IVA -->
            <!-- <v-col cols="12" sm="6" md="6" class="py-0">
              <v-autocomplete
                v-model="tipoIvaSelected"
                :items="tiposIva"
                label="Condición de IVA"
                item-text="value"
                item-value="id"
                outlined
                clearable
                dense
              >
              </v-autocomplete>
            </v-col> -->
            <!-- Forma Pago -->
            <v-col cols="12" sm="6" md="6" class="py-0">
              <v-autocomplete
                v-model="formaPagoSelected"
                :items="formasPagos"
                label="Forma de pago"
                item-text="value"
                :rules="rules.required"
                item-value="id"
                outlined
                dense
              >
              </v-autocomplete>
            </v-col>
            <!-- Entidad financiera -->
            <v-col cols="12" sm="6" md="6" class="py-0">
              <v-autocomplete
                v-model="entidadFinancieraSelected"
                :items="entidadesFinancieras"
                label="Entidad financiera"
                clearable
                item-text="value"
                item-value="id"
                outlined
                dense
              >
              </v-autocomplete>
            </v-col>
            <!-- Suc. Pago -->
            <!-- <v-col cols="12" sm="6" md="4" class="py-0">
              <v-autocomplete
                v-model="sucPagoSelected"
                :items="sucursales"
                label="Sucursal de pago"
                item-text="value"
                item-value="id"
                outlined
                clearable
                dense
              >
              </v-autocomplete>
            </v-col> -->

            <!-- Número de tarjeta/cuenta -->
            <v-col cols="12" sm="6" md="6" class="py-0">
              <v-text-field
                type="text"
                outlined
                dense
                v-mask="'################'"
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                :rules="[rules.maxLength(numeroCuenta, 16)]"
                label="N° (Tarjeta/Cuenta)"
                v-model="numeroCuenta"
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <!-- CBU -->
            <v-col cols="12" sm="6" md="6" class="py-0">
              <v-text-field
                type="text"
                outlined
                dense
                v-mask="'######################'"
                :rules="[rules.maxLength(cbu, 22)]"
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                label="CBU"
                v-model="cbu"
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <!-- Vencimiento -->
            <v-col cols="12" sm="6" md="4" class="py-0">
              <v-text-field
                type="text"
                outlined
                dense
                :rules="
                  numeroCuenta != null
                    ? rules.required.concat(rules.periodoMmYy)
                    : rules.periodoMmYy
                "
                v-mask="'##/##'"
                onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                label="Vencimiento de tarjeta"
                hint="Formato MM/AA"
                persistent-hint
                v-model="vencimientoTarjeta"
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <!-- Vigencia desde -->
            <v-col cols="12" sm="6" md="4" class="py-0">
              <v-text-field
                type="text"
                outlined
                dense
                :rules="
                  rules.required.concat(
                    [rules.validDateRange(periodoDesde, periodoHasta)],
                    rules.periodoYyyyMm
                  )
                "
                v-mask="'######'"
                onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                label="Vigencia desde"
                hint="Formato AAAAMM"
                persistent-hint
                v-model="periodoDesde"
                autocomplete="off"
              >
              </v-text-field>
            </v-col>
            <!-- Vigencia hasta -->
            <v-col cols="12" sm="6" md="4" class="py-0">
              <v-text-field
                type="text"
                outlined
                dense
                :rules="
                  rules.required.concat(
                    [rules.validDateRange(periodoDesde, periodoHasta)],
                    rules.periodoYyyyMm
                  )
                "
                v-mask="'######'"
                onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                label="Vigencia hasta"
                hint="Formato AAAAMM"
                persistent-hint
                v-model="periodoHasta"
                autocomplete="off"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal()">
          Cancelar
        </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import { mask } from "vue-the-mask";

export default {
  name: "FormasPagoAgente",
  props: {
    idFormaPago: { type: Number, required: false, default: null },
    agectaNom: { type: String, required: true, default: null },
    agectaId: { type: Number, required: false, default: null }
  },

  directives: { mask },
  created() {
    this.setSelects();
    if (this.idFormaPago !== null) {
      this.setFormaPago();
    }
  },
  data() {
    return {
      title: "Nueva forma de pago del agente",
      tipoIvaSelected: null,
      tiposIva: [],
      ingBrutos: null,
      sucPagoSelected: null,
      sucursales: [],
      formaPagoSelected: null,
      formasPagos: [],
      entidadFinancieraSelected: null,
      entidadesFinancieras: [],
      rules: rules,
      numeroCuenta: null,
      cbu: null,
      isFormValid: false,
      vencimientoTarjeta: null,
      agenteCuenta: `${this.agectaNom} - ${this.agectaId}`,
      periodoDesde: null,
      periodoHasta: null
    };
  },
  methods: {
    ...mapActions({
      getSucPagos: "afiliaciones/getSucPagos",
      getTiposIva: "afiliaciones/getTiposIva",
      getEntidadesFinancieras: "devengamientos/getEntidadesFinancieras",
      getFormasDePago: "devengamientos/getFormasDePago",
      setAlert: "user/setAlert",
      getFormasPagoAgente: "agenteCuenta/getFormasPagoAgente",
      getFormaPagoAgenteById: "agenteCuenta/getFormaPagoAgenteById",
      saveFormaPagoAgente: "agenteCuenta/saveFormaPagoAgente",
      deleteFormaPagoAgente: "agenteCuenta/deleteFormaPagoAgente"
    }),
    
    async setSelects() {
      const response2 = await this.getSucPagos();
      this.sucursales = response2;
      const result2 = await this.getTiposIva();
      this.tiposIva = result2;
      const data = await this.getEntidadesFinancieras();
      this.entidadesFinancieras = data;
      const res = await this.getFormasDePago();
      this.formasPagos = res;
    },
    async setFormaPago() {
      this.title = "Editar forma de pago del agente";
      const response = await this.getFormaPagoAgenteById(this.idFormaPago);
      this.cbu = response.cbu;
      this.entidadFinancieraSelected = response.entFinId;
      this.formaPagoSelected = response.fPagoId;
      this.numeroCuenta = response.numeroCuenta;
      this.periodoDesde = response.periodoDesde;
      this.periodoHasta = response.periodoHasta;
      this.vencimientoTarjeta = response.vencimiento;
      // this.sucPagoSelected = response.sucPagoId;
    },
    async saveEdit() {
      const vencimiento =
        this.vencimientoTarjeta != null
          ? this.vencimientoTarjeta.split("/")
          : null;
      const datosFormaPago = {
        movFPagoId: this.idFormaPago,
        ageId: this.agectaId,
        cbu: this.cbu != null ? this.cbu : "    ",
        entFinId:
          this.entidadFinancieraSelected != null
            ? this.entidadFinancieraSelected
            : 0,
        fPagoId: this.formaPagoSelected,
        periodoDesde: this.periodoDesde,
        periodoHasta: this.periodoHasta,
        numeroCuenta: this.numeroCuenta != null ? this.numeroCuenta : "    ",
        mesVencimiento: vencimiento != null ? vencimiento[0] : null,
        añoVencimiento: vencimiento != null ? vencimiento[1] : null
      };
      const response = await this.saveFormaPagoAgente(datosFormaPago);
      if (response.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito" });
        this.closeModal();
      }
    },
    closeModal() {
      this.$emit("toggleModalEditFormaPago");
    }
  }
};
</script>

<style scoped>
::v-deep .theme--light.v-input input {
  color: rgb(56, 56, 56);
}
::v-deep .v-input__slot {
  cursor: context-menu !important;
}
</style>

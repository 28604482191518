<template>
  <v-card>
    <v-container>
      <v-card-title class="pt-0">
        <v-row>
          <v-col cols="10">
            <span class="primary--text">{{ title }}</span></v-col
          >
        </v-row>
      </v-card-title>
      <v-card-text class="py-0 mb-0">
        <v-row justify="space-around">
          <!-- numero -->
          <v-col cols="12" sm="6" md="2" class="py-0 my-0 d-flex justify-center" >
            <v-text-field
              type="text"
              outlined
              dense
              filled
              disabled
              readonly
              class="theme--light.v-input input"
              label="Número de afiliado"
              v-model="afiliado.id"
              autocomplete="off"
            ></v-text-field>
          </v-col>
          <!-- nombre y apellido -->
          <v-col cols="12" sm="6" md="5" class="py-0 my-0 d-flex justify-center" >
            <v-text-field
              v-model="afiliado.name"
              label="Apellido y nombre de afiliado"
              filled
              disabled
              readonly
              class="theme--light.v-input input"
              outlined
              dense
              type="text"
              autocomplete="off"
            >
            </v-text-field>
          </v-col>
          <!-- Tipo de documento -->
          <v-col cols="12" sm="6" md="2" class="py-0 my-0 d-flex justify-center"  >
            <v-text-field
              type="text"
              outlined
              dense
              disabled
              filled
              readonly
              class="theme--light.v-input input"
              label="Tipo de documento"
              v-model="afiliado.tipoDocumento"
              autocomplete="off"
            ></v-text-field>
          </v-col>
          <!-- Número de documento -->
          <v-col cols="12" sm="6" md="3" class="py-0 my-0 d-flex justify-center" >
            <v-text-field
              type="text"
              outlined
              dense
              disabled
              filled
              readonly
              class="theme--light.v-input input"
              label="Número de documento"
              v-model="afiliado.documento"
              autocomplete="off"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <div>
        <v-card-text class="py-0 my-0">
          <v-row>
            <v-col cols="12">
              <v-data-table
                :headers="headersAfiliaciones"
                :items="listAfiliaciones"
                class="elevation-1 my-0 py-0"
                :hide-default-footer="true"
                :search="search"
                dense
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-row>
                      <v-col cols="6" align-self="center">
                        <v-text-field
                          v-model="search"
                          :append-icon="searchIcon"
                          label="Buscar"
                          single-line
                        ></v-text-field>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="4" class="mt-5">
                        <v-checkbox
                          v-model="fechaVigente"
                          v-bind:label="fechaVigenteText"
                          @change="loadAfiliaciones()"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="2" align-self="center">
                        <v-btn
                          color="primary"
                          @click="openModalNuevaAfiliacion()"
                          class="to-right mt-1"
                        >
                          Nuevo
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        size="20"
                        v-on="on"
                        @click="openModalEditAfiliacion(item)"
                      >
                        {{ editIcon }}
                      </v-icon>
                    </template>
                    <span>Editar afiliación</span>
                  </v-tooltip>
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        size="20"
                        v-on="on"
                        @click="deleteAfiliacion(item)"
                      >
                        {{ deleteIcon }}
                      </v-icon>
                    </template>
                    <span>Eliminar afiliación</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="closeModal()"> Cerrar </v-btn>
      </v-card-actions>
      <v-dialog
        v-if="openModalEditAfiliacionBan"
        v-model="openModalEditAfiliacionBan"
        max-width="570px"
        @keydown.esc="closeModalEditAfiliacion"
        persistent
      >
        <EditOtrasAfiliacionesAfiliado
          :afiliacion="afiliacion"
          :benId="idBen"
          :listAfiliaciones="listAfiliaciones"
          :titleCardProp="titleCard"
          @closeModalEditAfiliacion="closeModalEditAfiliacion"
        ></EditOtrasAfiliacionesAfiliado>
      </v-dialog>
      <DeleteDialog
        :titleProp="titleDelete"
        :openDelete.sync="showDeleteModal"
        @onDeleteItem="confirmDelete()"
      />
    </v-container>
  </v-card>
</template>

<script>
import enums from "@/utils/enums/index.js";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import DeleteDialog from "@/components/shared/DeleteDialog";
import EditOtrasAfiliacionesAfiliado from "./EditOtrasAfiliacionesAfiliado.vue";

export default {
  components: { DeleteDialog, EditOtrasAfiliacionesAfiliado },
  name: "OtrasAfiliacionesAfiliado",
  props: {
    afiliado: {
      type: Object,
      required: false
    },
    fechaConsultaAfi: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      title: enums.titles.OTRAS_AFILIACIONES_AFILIADO,
      titleCard: "",
      titleDelete: enums.titles.DELETE_OTRAS_AFILIACIONES_AFILIADO,
      enums: enums,
      editIcon: enums.icons.EDIT,
      calendarIcon: enums.icons.CALENDAR,
      editIcon: enums.icons.EDIT,
      deleteIcon: enums.icons.DELETE,
      searchIcon: enums.icons.SEARCH,
      headersAfiliaciones: [
        { text: "Número de afiliación", value: "numero", sortable: true },
        { text: "Tipo de afiliación",  value: "otroTipoAfi", sortable: true },
        { text: "Fecha desde", value: "fechaDesde", sortable: false },
        { text: "Fecha hasta", value: "fechaHasta", sortable: false },
        { text: "Acciones", value: "actions", sortable: false, align: "end" }
      ],
      listAfiliaciones: [],
      listAfiliacionesWithoutFilter: [],
      fechaVigente: true,
      afiliacionIDToDelete: null,
      OtraAfiliacionId: 0,
      fechaDesde: null,
      fechaHasta: null,
      afiliacion: null,
      search: "",
      fechaVigenteText: null,
      rules: rules,
      openModalEditAfiliacionesBan: false,
      openModalEditAfiliacionBan: false,
      showDeleteModal: false,
      idBen: null,
      allowedActions: null
    };
  },
  async created() {
    this.loadAfiliaciones();
    this.fechaVigenteText = "Ver registros activos al " + this.formatDate(this.fechaConsultaAfi);
  },
  methods: {
    ...mapActions({
      fetchOtrasAfiliaciones: "afiliaciones/fetchOtrasAfiliaciones",
      deleteRegistroOtrasAfiliaciones:
        "afiliaciones/deleteRegistroOtrasAfiliaciones",
      saveOtraAfiliacion: "afiliaciones/saveOtraAfiliacion",
      getOtroTipoNumeroAfiliado: "afiliaciones/getOtroTipoNumeroAfiliado",
      setAlert: "user/setAlert"
    }),
    closeModal() {
      this.$emit("toggleModalOtrasAfiliacionesAfiliado");
    },
    async loadAfiliaciones() {
      let fecha = null
      if(this.fechaVigente) fecha = this.fechaConsultaAfi;
      const response = await this.fetchOtrasAfiliaciones({ benId: this.afiliado.id, fechaConsulta:fecha});
      this.listAfiliaciones = response;
    },
    closeModalEditAfiliaciones() {
      this.openModalEditAfiliacionesBan = false;
    },
    openModalEditAfiliaciones() {
      this.openModalEditAfiliacionesBan = true;
    },
    closeAndReload() {
      this.openModalEditAfiliacion = false;
    },
    closeModalEditAfiliacion() {
      this.titleCard = "";
      this.openModalEditAfiliacionBan = false;
      this.loadAfiliaciones();
    },
    openModalNuevaAfiliacion() {
      this.titleCard = this.enums.titles.NEW_OTRAS_AFILIAICONES_AFILIADO;
      this.idBen = this.afiliado.id;
      this.afiliacion = null;
      this.openModalEditAfiliacionBan = true;
    },
    openModalEditAfiliacion(item) {
      this.afiliacion = item;
      this.idBen = this.afiliado.id;
      this.titleCard = this.enums.titles.EDIT_OTRAS_AFILIACIONES_AFILIADO;
      this.openModalEditAfiliacionBan = true;
    },
    deleteAfiliacion(afiliacionToDelete) {
      this.showDeleteModal = true;
      this.afiliacionIDToDelete = afiliacionToDelete.otraAfiliacionId;
    },
    async confirmDelete() {
      const response = await this.deleteRegistroOtrasAfiliaciones(
        this.afiliacionIDToDelete
      );
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.loadAfiliaciones();
      }
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    }

  }
};
</script>

<style scoped>
::v-deep .theme--light.v-input input {
  color: rgb(56, 56, 56);
}

::v-deep .v-select__selection,
.v-select__selection--comma,
.v-select__selection--disabled input {
  color: black !important;
}

::v-deep .v-input__slot {
  cursor: context-menu !important;
}

::v-deep label {
  margin-bottom: 0;
}
</style>

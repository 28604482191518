<template>
  <v-container>
    <PageHeader :title="title" />
    <v-card>
      <v-data-table
        :headers="headers"
        item-key="afiliadoId"
        :items="resultsAfiliados"
        hide-default-footer
        :item-class="afiliadoSelectedInTable"
        class="elevation-1"
      >
        <template v-slot:[`item.selectAfi`]="{ item }">
          <v-icon v-if="item.selectAfi" small color="primary">
            {{ checkIcon }}
          </v-icon>
        </template>
        <template v-slot:top>
          <v-toolbar flat>
            <v-row>
              <v-col cols="4" class="py-0 pl-0">
                <v-card-title>Integrantes</v-card-title>
              </v-col>
              <v-col cols="8" align="end" v-if="canCreate">
                <v-btn
                  color="primary"
                  @click="nuevoFamiliar()"
                  class="to-right"
                  :disabled="!groupWithTitular"
                >
                  Agregar familiar
                </v-btn>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left v-if="canSeeGestionMovimientos">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                @click="openGestionMovimientosModal()"
                v-show="item.selectAfi"
              >
                {{ movement }}
              </v-icon>
            </template>
            <span>Gestión de movimientos</span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                @click="selectAfiliado(item)"
              >
                {{ check }}
              </v-icon>
            </template>
            <span>Seleccionar afiliado</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-if="this.modalSelectAfiliado === true"
      v-model="modalSelectAfiliado"
      max-width="40%"
    >
      <v-card>
        <v-container>
          <v-card-text>
            <v-card-title class="headline primary--text">
              ¿Desea seleccionar al afiliado
              <span> {{ afiliado.name }} ?</span>
            </v-card-title>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="closeModalSelectAfiliado()"> Cancelar </v-btn>
            <v-btn text color="primary" dark @click="afiliadoSeleccionado()">
              Confirmar
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card></v-dialog
    >
    <v-row>
      <v-col cols="12" md="6" sm="3" v-for="item in referencias" :key="item.id">
        <v-card color="primary" dark @click="whereToPush(item)" height="100px">
          <v-card-title class="text-h5">
            {{ item.title }}
          </v-card-title>

          <v-card-subtitle>{{ item.subtitle }} </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog persistent v-if="asistente" v-model="asistente" max-width="600px">
      <AsistenteAfiliadoDialog
        @closeAsistente="closeAsistente"
        :benGrId="benGrId"
        :benId="this.benId"
      />
    </v-dialog>
    <v-dialog
      v-if="modalEmpresaAfiliado"
      v-model="modalEmpresaAfiliado"
      max-width="95%"
      @keydown.esc="toggleModalEmpresaAfiliado"
      persistent
    >
      <EditEmpresaAfiliado
        :benId="afiliado.id"
        :fechaConsultaAfi="fechaConsultaAfi"
        :afiliado="afiliado"
        @toggleModalEmpresaAfiliado="toggleModalEmpresaAfiliado"
      ></EditEmpresaAfiliado>
    </v-dialog>
    <v-dialog
      v-if="modalAgenteCuentaAfiliado"
      v-model="modalAgenteCuentaAfiliado"
      @keydown.esc="toggleModalAgenteCuentaAfiliado"
      max-width="1200px"
      persistent
    >
      <AgenteCuentaAfiliado
        :benGrId="afiliado.grupoFamiliar"
        :benId="this.benId"
        :fechaConsultaAfi="fechaConsultaAfi"
        @toggleModalAgenteCuentaAfiliado="toggleModalAgenteCuentaAfiliado"
      ></AgenteCuentaAfiliado>
    </v-dialog>
    <v-dialog
      v-if="modalOtrasAfiliacionesBan"
      v-model="modalOtrasAfiliacionesBan"
      @keydown.esc="toggleModalOtrasAfiliacionesAfiliado"
      max-width="85%"
      persistent
    >
      <OtrasAfiliacionesAfiliado
        :afiliado="this.afiliado"
        :fechaConsultaAfi="fechaConsultaAfi"
        @toggleModalOtrasAfiliacionesAfiliado="
          toggleModalOtrasAfiliacionesAfiliado
        "
      ></OtrasAfiliacionesAfiliado>
    </v-dialog>
    <v-dialog
      v-if="modalAportesEsperados"
      v-model="modalAportesEsperados"
      @keydown.esc="toggleModalAportesEsperadosAfiliado"
      max-width="70%"
      persistent
    >
      <EditAportesEsperados
        @toggleModalAportesEsperadosAfiliado="
          toggleModalAportesEsperadosAfiliado
        "
        :benGrId="this.afiliado.grupoFamiliar"
      >
      </EditAportesEsperados> </v-dialog
    ><v-dialog
      v-if="modalBeneficiosAfiliado"
      v-model="modalBeneficiosAfiliado"
      @keydown.esc="toggleModalBeneficiosAfiliado"
      max-width="85%"
      persistent
    >
      <BeneficiosAfiliado
        :afiliado="this.afiliado"
        :fechaConsultaAfi="this.fechaConsultaAfi"
        @toggleModalBeneficiosAfiliado="toggleModalBeneficiosAfiliado"
      >
      </BeneficiosAfiliado>
    </v-dialog>
    <v-dialog
      v-if="modalMedicoCabeceraAfiliado"
      v-model="modalMedicoCabeceraAfiliado"
      @keydown.esc="toggleModalMedicoCabeceraAfiliado"
      max-width="70%"
      persistent
    >
      <MedicoCabeceraAfiliado
        @toggleModalMedicoCabeceraAfiliado="toggleModalMedicoCabeceraAfiliado"
        :benId="afiliado.id"
        :afiliadoNom="afiliado.name"
      >
      </MedicoCabeceraAfiliado>
    </v-dialog>
    <v-dialog
      v-if="modalGerenciadorasDeAfiliado"
      v-model="modalGerenciadorasDeAfiliado"
      @keydown.esc="toggleModalGerenciadorasDeAfiliado"
      max-width="85%"
      persistent
    >
      <GerenciadorasDeAfiliado
        :benId="afiliado.id"
        :fechaConsultaAfi="fechaConsultaAfi"
        :afiliado="afiliado"
        @toggleModalGerenciadorasDeAfiliado="toggleModalGerenciadorasDeAfiliado"
      >
      </GerenciadorasDeAfiliado>
    </v-dialog>
    <v-dialog
      v-if="openModalGestionMovimientos"
      v-model="openModalGestionMovimientos"
      @keydown.esc="closeModalGestionMovimientos"
      max-width="1100px"
      persistent
    >
      <GestionMovimientosAfiliado
        :benId="afiliado.id"
        :afiliadoNom="afiliado.name"
        @closeGestionMovimientos="closeGestionMovimientosModal"
      ></GestionMovimientosAfiliado>
    </v-dialog>
    <v-row>
      <v-col cols="12" align="end">
        <v-btn color="primary" @click="goToGestionAfiliados()" class="to-right">
          Finalizar
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import enums from "@/utils/enums/index.js";
import AsistenteAfiliadoDialog from "@/components/modules/afiliaciones/afiliados/AsistenteAfiliadoDialog.vue";
import { mapActions } from "vuex";
import EditEmpresaAfiliado from "./EditEmpresaAfiliado.vue";
import AgenteCuentaAfiliado from "./AgenteCuentaAfiliado.vue";
import OtrasAfiliacionesAfiliado from "./OtrasAfiliacionesAfiliado.vue";
import EditAportesEsperados from "./EditAportesEsperados.vue";
import BeneficiosAfiliado from "./BeneficiosAfiliado.vue";
import MedicoCabeceraAfiliado from "./MedicoCabeceraAfiliado.vue";
import GerenciadorasDeAfiliado from "./GerenciadorasDeAfiliado.vue";
import GestionMovimientosAfiliado from "@/components/modules/afiliaciones/afiliados/GestionMovimientosAfiliado.vue";

export default {
  name: "DatosComplementariosAfiliado",
  components: {
    PageHeader,
    AsistenteAfiliadoDialog,
    EditEmpresaAfiliado,
    AgenteCuentaAfiliado,
    OtrasAfiliacionesAfiliado,
    EditAportesEsperados,
    BeneficiosAfiliado,
    MedicoCabeceraAfiliado,
    GerenciadorasDeAfiliado,
    GestionMovimientosAfiliado
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  created() {
    if (this.$route.params.asistenteOn) {
      this.asistente = this.$route.params.asistenteOn;
    }
    if (this.$route.params.benId) {
      this.benId = this.$route.params.benId;
      this.fechaConsultaAfi = this.$route.params.fechaConsulta;
      this.benGrId = this.$route.params.benGrId;
      this.canSeeGestionMovimientos = this.$route.params.canSeeGestionMovimientos;
      this.setIntegrantes();
    }
  },
  data() {
    return {
      canCreate: false,
      title: "Datos complementarios del afiliado",
      openModalGestionMovimientos: false,
      canSeeGestionMovimientos: false,
      referencias: [
        {
          id: 0,
          title: "Empresas",
          subtitle: "Empresas donde trabaja el afiliado.",
          path: "EditEmpresaAfiliado"
        },
        {
          id: 1,
          title: "Agente de cuenta",
          subtitle: "Persona o empresa que paga la cuota del grupo familiar.",
          path: "AgenteCuentaAfiliado"
        },
        {
          id: 2,
          title: "Aportes esperados",
          subtitle: "Importe total de aportes esperados del grupo familiar.",
          path: "EditAportesEsperados"
        },
        {
          id: 3,
          title: "Otras afiliaciones",
          subtitle: "Otras obras sociales de cada titular o familiar.",
          path: "OtrasAfiliacionesAfiliado"
        },
        {
          id: 4,
          title: "Médico de cabecera",
          // subtitle: "Otras obras sociales de cada titular o familiar.",
          path: "MedicoCabeceraAfiliado"
        },
        {
          id: 5,
          title: "Beneficios ANSES",
          // subtitle: "",
          path: "BeneficiosAfiliado"
        },
        {
          id: 6,
          title: "Gerenciadoras",
          // subtitle: "",
          path: "GerenciadorasDeAfiliado"
        }
      ],
      benId: null,
      benGrId: null,
      asistente: false,
      checkIcon: enums.icons.CHECK_OUTLINE,
      check: enums.icons.CHECK,
      movement: enums.icons.ACCOUNT_MOVEMENT,
      fechaConsultaAfi: null,
      modalSelectAfiliado: false,
      headers: [
        {
          text: "Apellido/s y nombre/s",
          align: "start",
          sortable: false,
          value: "nombre"
        },
        {
          text: "Número de afiliado",
          sortable: false,
          value: "nroAfiliado"
        },
        {
          text: "Parentesco",
          sortable: false,
          value: "parentescoNom"
        },
        {
          text: "Convenio",
          sortable: false,
          value: "convenio.nombre"
        },
        {
          text: "Plan",
          sortable: false,
          value: "plan.nombre"
        },
        {
          text: "Seleccionado",
          sortable: false,
          value: "selectAfi",
          align: "center"
        },
        { text: "Acciones", value: "actions", sortable: false, align: "end" }
      ],
      resultsAfiliados: [],
      afiliado: {},
      modalEmpresaAfiliado: false,
      modalBeneficiosAfiliado: false,
      newAfiFamiliar: false,
      modalAgenteCuentaAfiliado: false,
      modalOtrasAfiliacionesBan: false,
      modalAportesEsperados: false,
      domicilioTitular: null,
      convenioTitular: null,
      planTitular: null,
      modalMedicoCabeceraAfiliado: false,
      modalGerenciadorasDeAfiliado: false,
      subConvenioTitular: null,
      groupWithTitular: false
    };
  },
  methods: {
    ...mapActions({
      getIntegrantesGrupoFamiliar: "afiliaciones/getIntegrantesGrupoFamiliar"
    }),
     setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.NUEVO_AFILIADO:
            this.canCreate = true;
            break;
            default:
            break;
        }
      });
    },
    async setIntegrantes() {
      const response = await this.getIntegrantesGrupoFamiliar({
        benGrId: this.benGrId,
        fechaConsulta: this.fechaConsultaAfi
      });
      this.resultsAfiliados = response;
      this.resultsAfiliados.forEach(element => {
        if (element.esTitular) {
          this.groupWithTitular = true;
          return;
        }
      });
      this.resultsAfiliados.forEach(x => {
        if (x.benId === this.$route.params.benId) {
          x.selectAfi = true;
          this.afiliado = {
            id: x.benId,
            grupoFamiliar: x.benGrId,
            name: x.nombre,
            selectAfi: x.selectAfi,
            parentesco: x.parentesco,
            domicilioLegal: x.domicilioLegal,
            documento: x.documento,
            tipoDocumento: x.tipoDocNom
          };
        }
      });
      this.$store.dispatch("user/changeAppLoading", {
        status: false,
        text: "",
        opaque: false
      });
      if (this.$route.params.openAgenteCtaCard) {
        this.modalAgenteCuentaAfiliado = this.$route.params.openAgenteCtaCard;
      }
    },
    whereToPush(item) {
      switch (item.id) {
        case 0:
          this.toggleModalEmpresaAfiliado();
          break;
        case 1:
          this.toggleModalAgenteCuentaAfiliado();
          break;
        case 2:
          this.toggleModalAportesEsperadosAfiliado();
          break;
        case 3:
          this.toggleModalOtrasAfiliacionesAfiliado();
          break;
        case 4:
          this.toggleModalMedicoCabeceraAfiliado();
          break;
        case 5:
          this.toggleModalBeneficiosAfiliado();
          break;
        case 6:
          this.toggleModalGerenciadorasDeAfiliado();
          break;
        default:
          break;
      }
    },
    closeAsistente() {
      this.asistente = false;
    },
    afiliadoSelectedInTable(item) {
      if (item.selectAfi === true) {
        return "secondary";
      }
    },
    selectAfiliado(item) {
      this.modalSelectAfiliado = true;
      this.afiliado = {
        id: item.benId,
        grupoFamiliar: item.benGrId,
        name: item.nombre,
        selectAfi: item.selectAfi,
        parentesco: item.parentesco,
        documento: item.documento,
        domicilioLegal: item.domicilioLegal,
        tipoDocumento: item.tipoDocNom
      };
    },
    nuevoFamiliar() {
      this.resultsAfiliados.forEach(x => {
        if (x.esTitular) {
          this.domicilioTitular = x.domicilioLegal;
          this.convenioTitular = x.convenio.id;
          this.subConvenioTitular = x.subconvenio.id;
          this.planTitular = x.plan.id;
        }
      });
      this.newAfiFamiliar = true;
      this.$router.push({
        name: "EditAfiliado",
        params: {
          newAfiFamiliar: this.newAfiFamiliar,
          benGrId: this.afiliado.grupoFamiliar,
          fechaConsulta: this.fechaConsultaAfi,
          afiliadoId: this.afiliado.id,
          domicilioTitular: this.domicilioTitular,
          convenioTitular: this.convenioTitular,
          subConvenioTitular: this.subConvenioTitular,
          planTitular: this.planTitular
        }
      });
    },
    closeModalSelectAfiliado() {
      this.modalSelectAfiliado = false;
    },
    toggleModalEmpresaAfiliado() {
      this.modalEmpresaAfiliado = !this.modalEmpresaAfiliado;
    },
    toggleModalAgenteCuentaAfiliado() {
      this.modalAgenteCuentaAfiliado = !this.modalAgenteCuentaAfiliado;
    },
    toggleModalOtrasAfiliacionesAfiliado() {
      this.modalOtrasAfiliacionesBan = !this.modalOtrasAfiliacionesBan;
    },
    toggleModalBeneficiosAfiliado() {
      this.modalBeneficiosAfiliado = !this.modalBeneficiosAfiliado;
    },
    toggleModalGerenciadorasDeAfiliado() {
      this.modalGerenciadorasDeAfiliado = !this.modalGerenciadorasDeAfiliado;
    },
    toggleModalMedicoCabeceraAfiliado() {
      this.modalMedicoCabeceraAfiliado = !this.modalMedicoCabeceraAfiliado;
    },
    toggleModalAportesEsperadosAfiliado() {
      this.modalAportesEsperados = !this.modalAportesEsperados;
    },
    afiliadoSeleccionado() {
      this.resultsAfiliados.forEach(x => {
        if (x.benId === this.afiliado.id) {
          x.selectAfi = true;
        }
        if (x.benId !== this.afiliado.id) {
          x.selectAfi = false;
        }
      });
      this.closeModalSelectAfiliado();
    },
    goToGestionAfiliados() {
      if (this.$route.params.asistenteOn) {
        const datosAfiliado = {
          nombre: this.$route.params.newAfiliado.nombre,
          apellido: this.$route.params.newAfiliado.apellido,
          dni: this.$route.params.newAfiliado.dNI
        };
        this.$router.push({
          name: "GestionAfiliados",
          params: {
            nuevoAfiliado: true,
            datosAfiliado: datosAfiliado
          }
        });
        this.$store.dispatch("user/changeAppLoading", {
          status: true,
          text: "",
          opaque: true
        });
      } else {
        this.$router.push({
          name: "GestionAfiliados",
          params: {
            fromEdit: true
          }
        });
        this.$store.dispatch("user/changeAppLoading", {
          status: true,
          text: "",
          opaque: true
        });
      }
    },
    openGestionMovimientosModal() {
      this.openModalGestionMovimientos = true;
    },
    closeGestionMovimientosModal() {
      this.openModalGestionMovimientos = false;
    }
  }
};
</script>

<style scoped></style>

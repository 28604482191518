<template>
  <v-card>
    <v-container>
      <v-card-title class="py-0">
        <v-row>
          <v-col cols="10">
            <span class="primary--text">{{ title }}</span>
          </v-col>
          <v-col cols="2" align="end">
            <v-btn
              color="primary"
              class="to-right"
              @click="openModalNuevoAgente()"
              v-if="canCreateAgenteCuenta"
            >
              Nuevo agente
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="py-0">
        <v-row v-if="sinAgente">
          <v-col cols="12">
            <h5 class="agente-msg">
              El grupo familiar no tiene un Agente de cuenta vigente a la fecha
              "{{ this.functionFormatDate(fechaConsultaAfi) }}".
            </h5>
          </v-col>
        </v-row>
        <v-row v-if="sinAgente === false">
          <!-- Codigo -->
          <v-col cols="12" sm="6" md="2" class="py-0">
            <v-text-field
              type="text"
              outlined
              dense
              filled
              disabled
              readonly
              class="theme--light.v-input input"
              label="Código"
              v-model="codigoAge"
              autocomplete="off"
            ></v-text-field>
          </v-col>
          <!-- Nombre completo -->
          <v-col cols="12" sm="6" md="3" class="py-0">
            <v-text-field
              v-model="nombreCompleto"
              label="Nombre o razón social"
              filled
              disabled
              readonly
              class="theme--light.v-input input"
              outlined
              dense
              type="text"
              autocomplete="off"
            >
            </v-text-field>
          </v-col>
          <!-- Número de documento -->
          <v-col cols="12" sm="6" md="2" class="py-0">
            <v-text-field
              type="text"
              outlined
              dense
              filled
              disabled
              readonly
              class="theme--light.v-input input"
              label="N° de documento"
              v-model="docAgente"
              autocomplete="off"
            ></v-text-field>
          </v-col>
          <!-- CUIL -->
          <v-col cols="12" sm="6" md="2" class="py-0">
            <v-text-field
              type="text"
              outlined
              filled
              disabled
              readonly
              class="theme--light.v-input input"
              dense
              label="CUIT"
              v-model="cuit"
              autocomplete="off"
            ></v-text-field>
          </v-col>
          <!-- Condicion iva -->
          <v-col cols="12" sm="6" md="2" class="py-0">
            <v-text-field
              v-model="condIva"
              label="Condición de IVA"
              filled
              disabled
              readonly
              class="theme--light.v-input input"
              outlined
              dense
            >
            </v-text-field>
          </v-col>
          <!-- Edit Icon -->
          <v-col cols="12" sm="6" md="1" class="py-0 to-right">
            <div v-if="canEditAgenteCuenta">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="25"
                    v-on="on"
                    color="primary"
                    class="mt-1"
                    @click="editAgenteCuenta()"
                  >
                    {{ editIcon }}
                  </v-icon>
                </template>
                <span>Editar agente</span>
              </v-tooltip>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="sinAgente === false">
          <!-- Domicilio -->
          <v-col cols="12" sm="6" md="9" class="py-0">
            <v-text-field
              type="text"
              outlined
              filled
              disabled
              readonly
              class="theme--light.v-input input"
              dense
              label="Domicilio"
              v-model="domicilioCompleto"
              autocomplete="off"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="sinAgente === false">
          <!-- Forma de pago -->
          <v-col cols="12" sm="6" md="9" class="py-0">
            <v-text-field
              type="text"
              outlined
              dense
              filled
              disabled
              readonly
              class="theme--light.v-input input"
              label="Forma de pago"
              v-model="formaPagoCompleta"
              autocomplete="off"
            ></v-text-field>
          </v-col>
          <v-col cols="3" md="1" align="end" class="py-0">
            <div v-if="canSeeFormasPagoAgenteCuenta">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="25"
                    v-on="on"
                    color="primary"
                    class="mt-1"
                    @click="toggleModalEditFormaPago()"
                  >
                    {{ editIcon }}
                  </v-icon>
                </template>
                <span>Gestionar formas de pago</span>
              </v-tooltip>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <div v-if="historialAgentes.length > 0">
        <v-card-title class="py-0">
          Historial de Agentes de cuenta del grupo familiar
        </v-card-title>
        <v-card-text class="py-0">
          <v-row>
            <v-col cols="12">
              <v-data-table
                :headers="headersHistorialAgentes"
                :items="historialAgentes"
                class="elevation-1"
                :hide-default-footer="true"
                dense
              >
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="closeModal()" class="to-right">
          Cerrar
        </v-btn>
      </v-card-actions>
      <v-dialog
        v-if="modalNuevoAgente"
        v-model="modalNuevoAgente"
        max-width="500px"
        @keydown.esc="closeModalNuevoAgenteCuenta"
        persistent
      >
        <v-card>
          <v-container>
            <v-card-title class="headline primary--text">
              Nuevo Agente de cuenta
            </v-card-title>
            <v-card-text>
              <div class="text-center" v-if="nuevoAgenteBtns">
                <v-row>
                  <v-col cols="12">
                    <v-btn
                      color="primary"
                      width="85%"
                      @click="ageCtaFromTitular()"
                      >Desde el titular del grupo familiar</v-btn
                    >
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      color="primary"
                      width="85%"
                      @click="asignarAgenteCuentaExistente()"
                      >Asignar un agente de cuenta existente</v-btn
                    >
                  </v-col>
                  <v-col cols="12">
                    <v-btn color="primary" width="85%" @click="newAgenteCta()"
                      >Asignar un nuevo Agente de cuenta</v-btn
                    >
                  </v-col>
                </v-row>
              </div>
              <div class="text-center mt-4" v-if="loading">
                <v-progress-circular
                  :size="50"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                dark
                @click="closeModalNuevoAgenteCuenta()"
              >
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-container>
        </v-card>
      </v-dialog>
      <v-dialog
        v-if="openModalDatosAdicionalesAgente"
        v-model="openModalDatosAdicionalesAgente"
        max-width="45rem"
        @keydown.esc="closeModalDatosAdicionalesAgente"
        persistent
      >
        <DatosAdicionalesAgenteCuenta
          :agenteCta="agenteCta"
          :fromDatosComplementariosAfiliado="true"
          :requestPeriodos="requestPeriodosAgente"
          :agectaId="agectaId"
          :nroGrupo="this.benGrId"
          :periodoDesde="this.fechaConsultaAfi"
          @closeAndReload="closeAndReload"
        />
      </v-dialog>
      <v-dialog
        v-if="openModalNuevaFormaPago"
        v-model="openModalNuevaFormaPago"
        max-width="80%"
        @keydown.esc="toggleModalEditFormaPago()"
        persistent
      >
        <FormasPagoAgente
          :agectaId="codigoAge"
          :agectaNom="nombreCompleto"
          @toggleModalEditFormaPago="toggleModalEditFormaPago"
        ></FormasPagoAgente>
      </v-dialog>
    </v-container>
  </v-card>
</template>

<script>
import enums from "@/utils/enums/index.js";
import { mapActions } from "vuex";
import moment from "moment";
import FormasPagoAgente from "@/components/modules/cuotas/agenteCuenta/FormasPagoAgente.vue";
import DatosAdicionalesAgenteCuenta from "@/components/modules/afiliaciones/afiliados/DatosAdicionalesAgenteCuenta.vue";

export default {
  name: "AgenteCuentaAfiliado",
  props: {
    benGrId: {
      type: Number,
      required: true
    },
    fechaConsultaAfi: {
      type: String,
      required: true
    },
    benId: {
      type: Number,
      required: true
    }
  },
  components: { DatosAdicionalesAgenteCuenta, FormasPagoAgente },
  data() {
    return {
      title: "Agente de cuenta del grupo familiar",
      editIcon: enums.icons.EDIT,
      headersHistorialAgentes: [
        { text: "Código", value: "ageId", sortable: false },
        { text: "Nombre o razón social", value: "nombre", sortable: false },
        { text: "N° de documento", value: "documento", sortable: false },
        { text: "CUIT", value: "cuil", sortable: false },
        { text: "Condición de IVA", value: "condIvaNom", sortable: false },
        { text: "Período desde", value: "periodoDesde", sortable: false },
        { text: "Período hasta", value: "periodoHasta", sortable: false }
      ],
      historialAgentes: [],
      codigoAge: null,
      requestPeriodosAgente: true,
      nombreCompleto: null,
      docAgente: null,
      cuit: null,
      condIva: null,
      domicilioCompleto: null,
      formaPagoCompleta: null,
      sinAgente: false,
      modalNuevoAgente: false,
      openModalDatosAdicionalesAgente: false,
      agectaId: null,
      nuevoAgenteBtns: true,
      loading: false,
      periodoDesde: null,
      fechaConsultaAfiliado: null,
      openModalNuevaFormaPago: false,
      closeModalNuevaFormaPago: false,
      idFormaPago: null,
      canCreateAgenteCuenta: false,
      canEditAgenteCuenta: false,
      agenteCta: null,
      canSeeFormasPagoAgenteCuenta: false
    };
  },
  created() {
    this.setPermisosAgenteCuenta();
    if (this.fechaConsultaAfi != null) {
      this.fechaConsultaAfiliado = this.fechaConsultaAfi;
    }
    if (this.$route.params.fechaConsultaAfi) {
      this.fechaConsultaAfiliado = this.$route.params.fechaConsultaAfi;
    }
    this.setAgenteCuentaByGrupoAndFecha();
    this.setHistorialAgentesCuenta();
  },
  methods: {
    ...mapActions({
      getAgenteCuentaByBenGr: "afiliaciones/getAgenteCuentaByBenGr",
      saveAgenteCuentaFromTitular: "afiliaciones/saveAgenteCuentaFromTitular",
      getHistorialAgentes: "afiliaciones/getHistorialAgentes",
      getPermisosAgenteCuenta: "afiliaciones/getPermisosAgenteCuenta",
      setAlert: "user/setAlert"
    }),
    async setPermisosAgenteCuenta() {
      const permisos = await this.getPermisosAgenteCuenta({
        optionCode: "69E7E01A-40A4-4BA7-9920-A7A372A75BE4"
      });
      this.canEditAgenteCuenta = permisos.canEdit;
      this.canCreateAgenteCuenta = permisos.canCreate;
      this.canSeeFormasPagoAgenteCuenta = permisos.canSeeFormasPago;
    },
    async setAgenteCuentaByGrupoAndFecha() {
      const response = await this.getAgenteCuentaByBenGr({
        benGrId: this.benGrId,
        fechaConsulta: this.fechaConsultaAfiliado
      });
      if (response === null) {
        this.sinAgente = true;
      } else {
        this.sinAgente = false;
        this.codigoAge = response.ageId;
        this.periodoDesde = response.periodoDesde;
        this.nombreCompleto = response.nombre;
        this.docAgente = response.documento;
        this.cuit = response.cuil;
        this.condIva = response.condIvaNom;
        this.domicilioCompleto = response.domicilio;
        this.formaPagoCompleta =
          response.formaPago != null
            ? response.formaPago
            : "No hay una forma de pago asignada a la fecha de consulta.";
      }
    },
    async setHistorialAgentesCuenta() {
      const response = await this.getHistorialAgentes({
        benGrId: this.benGrId
      });
      this.historialAgentes = response;
    },
    closeModal() {
      this.$emit("toggleModalAgenteCuentaAfiliado");
    },
    functionFormatDate(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }
    },
    openModalNuevoAgente() {
      this.modalNuevoAgente = true;
    },
    closeModalNuevoAgenteCuenta() {
      this.modalNuevoAgente = false;
      this.setAgenteCuentaByGrupoAndFecha();
      this.setHistorialAgentesCuenta();
    },
    async ageCtaFromTitular() {
      this.loading = true;
      this.nuevoAgenteBtns = false;
      const response = await this.saveAgenteCuentaFromTitular({
        benGr: this.benGrId
      });

      if (response.status === 200) {
        this.loading = false;
        this.nuevoAgenteBtns = true;
        this.agenteCta = {
          agenteId: null,
          nombre: response.data.data.agectaNombre,
          tipoAgenteId: 1,
          cuit: response.data.data.agectaCuit,
          calle: response.data.data.agectaCalle,
          numero: response.data.data.agectaNro,
          depto: response.data.data.agectaDepto,
          telefono: response.data.data.agectaTel,
          barrioId: response.data.data.barId,
          localidadId: response.data.data.locId,
          codPostal: response.data.data.codPostal,
          tdId: response.data.data.tdId,
          documento: response.data.data.docId,
          email: response.data.data.agectaEmail,
          piso: response.data.data.agectaPiso,
          benGrId: this.benGrId,
          fechaAlta: this.fechaConsultaAfi
        };
        this.openModalDatosAdicionalesAgente = true;
      }
    },
    newAgenteCta() {
      this.$router.push({
        name: "CargaAgentesCuenta",
        params: {
          benGrId: this.benGrId,
          afiliadoId: this.benId,
          fechaConsulta: this.fechaConsultaAfiliado,
          requestPeriodosAgente: this.requestPeriodosAgente,
          canSeeGestionMovimientos: this.$route.params.canSeeGestionMovimientos
        }
      });
    },
    closeModalDatosAdicionalesAgente() {
      this.openModalDatosAdicionalesAgente = false;
      this.setAgenteCuentaByGrupoAndFecha();
      this.setHistorialAgentesCuenta();
    },
    closeAndReload() {
      this.setAgenteCuentaByGrupoAndFecha();
      this.setHistorialAgentesCuenta();
      this.openModalDatosAdicionalesAgente = false;
      this.modalNuevoAgente = false;
    },
    editAgenteCuenta() {
      this.$router.push({
        name: "CargaAgentesCuenta",
        params: {
          agectaId: this.codigoAge,
          periodoDesde: this.periodoDesde,
          benGrId: this.benGrId,
          afiliadoId: this.benId,
          fechaConsulta: this.fechaConsultaAfiliado,
          requestPeriodosAgente: this.requestPeriodosAgente,
          canSeeGestionMovimientos: this.$route.params.canSeeGestionMovimientos
        }
      });
    },
    asignarAgenteCuentaExistente() {
      this.$router.push({
        name: "CargaAgentesCuenta",
        params: {
          asignarAgente: true,
          periodoDesde: this.periodoDesde,
          benGrId: this.benGrId,
          afiliadoId: this.benId,
          fechaConsulta: this.fechaConsultaAfiliado,
          requestPeriodosAgente: this.requestPeriodosAgente,
          canSeeGestionMovimientos: this.$route.params.canSeeGestionMovimientos
        }
      });
    },
    toggleModalEditFormaPago() {
      this.setAgenteCuentaByGrupoAndFecha();
      this.openModalNuevaFormaPago = !this.openModalNuevaFormaPago;
    }
  }
};
</script>

<style scoped>
.agente-msg {
  font-weight: bold !important;
  color: red !important;
}
::v-deep .theme--light.v-input input {
  color: rgb(56, 56, 56);
}

::v-deep .v-select__selection,
.v-select__selection--comma,
.v-select__selection--disabled input {
  color: black !important;
}

::v-deep .v-input__slot {
  cursor: context-menu !important;
}

::v-deep label {
  margin-bottom: 0;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"bcId","loading":_vm.loading},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('v-card-title',{staticClass:"pl-1 primary--text"},[_vm._v(_vm._s(_vm.title)+" ")])],1)],1)],1)]},proxy:true},{key:"item.liquidado",fn:function(ref){
var item = ref.item;
return [(item.liquidado)?_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.checkIcon)+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){item.liquidado
                  ? _vm.toggleModalVerLiquidacionesMovs(item)
                  : item.liquidadoAnteriormente
                  ? _vm.linkOrUnlinkLiquidacion(item)
                  : true}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(item.liquidado ? _vm.seeIcon : item.liquidadoAnteriormente ? _vm.vincularIcon : _vm.infoIcon)+" ")])]}}],null,true)},[(item.liquidado)?_c('span',[_vm._v("Ver liquidaciones")]):(item.liquidadoAnteriormente)?_c('span',[_vm._v(" Vincular liquidación ")]):_c('span',[_vm._v("No es posible vincular este movimiento a una liquidación")])])]}}],null,true)}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v(" Cerrar ")])],1)],1),(_vm.modalVerLiquidacionesMovs)?_c('v-dialog',{attrs:{"max-width":"70%","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.toggleModalVerLiquidacionesMovs($event)}},model:{value:(_vm.modalVerLiquidacionesMovs),callback:function ($$v) {_vm.modalVerLiquidacionesMovs=$$v},expression:"modalVerLiquidacionesMovs"}},[_c('VerLiquidacionesMovimientos',{attrs:{"benId":_vm.benId,"movItem":_vm.movItem},on:{"closeModal":_vm.toggleModalVerLiquidacionesMovs}})],1):_vm._e(),_c('DeleteDialog',{attrs:{"titleProp":_vm.titleDelete,"maxWidth":'25%',"textDelete":_vm.textDelete,"isLoading":_vm.btnDeleteIsLoading,"confirmButtonText":_vm.textBtnDelete,"openDelete":_vm.showDeleteModal},on:{"update:openDelete":function($event){_vm.showDeleteModal=$event},"update:open-delete":function($event){_vm.showDeleteModal=$event},"onDeleteItem":function($event){return _vm.confirmDelete()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
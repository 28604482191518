<template>
  <v-card >
    <v-container class="p-0" >
      <v-data-table
        :headers="headers"
        :items="movimientosAfiliado"
        class="elevation-1"
        item-key="fecha"
        :loading="loading"
        show-expand
        single-expand
        :expanded.sync="expanded"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-row>
              <v-col cols="10">
                <v-card-title class="pl-1 primary--text"
                  >{{ title }}
                </v-card-title>
              </v-col>
              <v-col cols="2" class="pt-5">
                <v-btn
                  class="to-right"
                  color="primary"
                  @click="toggleModalLiquidacionesMovs()"
                >
                  Liquidaciones
                </v-btn>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                @click="openModal(item)"
                v-if="item.ultimoMovimiento"
              >
                {{ editIcon }}
              </v-icon>
            </template>
            <span>Editar movimiento</span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                @click="deleteMovimientoAfiliadoModal(item)"
                v-if="item.ultimoMovimiento"
              >
                {{ deleteIcon }}
              </v-icon>
            </template>
            <span>Eliminar movimiento</span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                v-if="item.obsProcesoDesdeArchivo.length > 0"
                @click="
                  openObsDesdeArchivo(
                    item.obsProcesoDesdeArchivo,
                    item.tipoMovNombre
                  )
                "
              >
                {{ seeIcon }}
              </v-icon>
            </template>
            <span>Ver observación del proceso</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.data-table-expand`]="{ item, isExpanded }">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                @click="handleExpansion(item, isExpanded)"
                v-bind="attrs"
                size="20"
                v-on="on"
                >{{ isExpanded ? "mdi-account-check" : "mdi-account" }}</v-icon
              >
            </template>
            <span>Usuarios</span>
          </v-tooltip>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <strong>Fecha alta:</strong>
            {{ item.fechaAlta }}
            <strong style="padding-left: 100px">Usuario alta:</strong>
            {{ item.usuarioAlta }}
            <strong style="padding-left: 100px">Fecha modificación:</strong>
            {{ item.fechaModificacion }}
            <strong style="padding-left: 100px">Usuario modificación:</strong>
            {{ item.usuarioModificacion }}
          </td>
        </template>
      </v-data-table>
      <!-- Modal observaciones del proceso -->
      <v-dialog
        v-if="modalObsDesdeArchivo"
        v-model="modalObsDesdeArchivo"
        max-width="35%"
        @keydown.esc="modalObsDesdeArchivo = false"
        persistent
      >
        <v-card>
          <v-card-title class="py-0 primary--text">
            Observaciones de: {{ tipoMovNombre }}
          </v-card-title>
          <v-card-text class="py-0">{{ obsDesdeArchivo }} </v-card-text>
          
          <v-card-actions class="pt-0">
            <v-spacer></v-spacer>
            <v-btn small outlined class="text-right" @click="modalObsDesdeArchivo = false">
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-if="modalLiquidacionesMovs"
        v-model="modalLiquidacionesMovs"
        max-width="70%"
        @keydown.esc="toggleModalLiquidacionesMovs"
        persistent
      >
        <LiquidacionesMovimientosAfiliado
          :benId="benId"
          :afiliadoNom="afiliadoNom"
          @closeModal="toggleModalLiquidacionesMovs"
        ></LiquidacionesMovimientosAfiliado>
      </v-dialog>
      <v-dialog
        v-if="openModalEdit"
        v-model="openModalEdit"
        max-width="45%"
        @keydown.esc="closeAndReload"
        persistent
      >
        <EditMovimientoAfiliado
          :benId="benId"
          :movimientoAfiliado="movimientoAfiliado"
          @closeAndReload="closeAndReload"
        ></EditMovimientoAfiliado>
      </v-dialog>
      <DeleteDialog
        :titleProp="titleDelete"
        :maxWidth="'25%'"
        :textDelete="textValidateIntegrantesConvenio"
        :openDelete.sync="showDeleteModal"
        @onDeleteItem="confirmDelete()"
      />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal()"> Cerrar </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import DeleteDialog from "@/components/shared/DeleteDialog";
import EditMovimientoAfiliado from "@/components/modules/afiliaciones/afiliados/EditMovimientoAfiliado.vue";
import LiquidacionesMovimientosAfiliado from "@/components/modules/afiliaciones/afiliados/LiquidacionesMovimientosAfiliado.vue";

export default {
  components: {
    DeleteDialog,
    EditMovimientoAfiliado,
    LiquidacionesMovimientosAfiliado
  },
  name: "GestionMovimientosAfiliado",
  props: {
    benId: {
      type: Number
    },
    afiliadoNom: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      title: `Gestión de movimientos del afiliado: ${this.afiliadoNom}`,
      editIcon: enums.icons.EDIT,
      deleteIcon: enums.icons.DELETE,
      seeIcon: enums.icons.SEE,
      showDeleteModal: false,
      idsToDelete: null,
      titleDelete: "¿Eliminar movimiento?",
      textValidateIntegrantesConvenio:
        "El afiliado quedará con un convenio diferente a sus integrantes",
      movimientosAfiliado: [],
      headers: [
        {
          text: "Fecha",
          value: "fecha",
          sortable: false
        },
        {
          text: "Tipo de movimiento",
          value: "tipoMovNombre",
          sortable: false
        },
        {
          text: "Dato anterior",
          value: "datoAnterior",
          sortable: false
        },
        {
          text: "Dato nuevo",
          value: "datoNuevo",
          sortable: false
        },
        {
          text: "Observaciones",
          value: "observaciones",
          sortable: false
        },
        { text: "Acciones", value: "actions", sortable: false, align: "end" },
        { text: "", value: "data-table-expand", align: "end" }
      ],
      openModalEdit: false,
      loading: false,
      movimientoAfiliado: {},
      expanded: [],
      modalLiquidacionesMovs: false,
      modalObsDesdeArchivo: false,
      obsDesdeArchivo: "",
      tipoMovNombre: ""
    };
  },
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.loadMovimientosAfiliado();
  },

  methods: {
    ...mapActions({
      getMovimientosAfiliado: "configAfiliaciones/getMovimientosAfiliado",
      deleteMovimientoAfiliado: "configAfiliaciones/deleteMovimientoAfiliado",
      getIntegrantesGrupoFamiliar: "afiliaciones/fetchIntegrantesConCobertura",

      setAlert: "user/setAlert"
    }),
    openObsDesdeArchivo(observacion, tipoMovNombre) {
      this.modalObsDesdeArchivo = true;
      this.tipoMovNombre = tipoMovNombre;
      this.obsDesdeArchivo = observacion;
    },
    closeModal() {
      this.$emit("closeGestionMovimientos");
    },

    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
    async loadMovimientosAfiliado() {
      this.movimientosAfiliado = [];
      this.loading = true;
      const response = await this.getMovimientosAfiliado(this.benId);
      this.movimientosAfiliado = response;
      this.loading = false;
    },
    async deleteMovimientoAfiliadoModal(item) {
      var integrantesDistintoConvenio = [];
      if (
        item.tipoMovId.filter(x => x == 8).length > 0 &&
        !item.permiteCambiarConvIntegrante
      ) {
        const integrantesConCobertura = await this.getIntegrantesGrupoFamiliar({
          benId: this.benId,
          fechaConsulta: this.parseDateToIso(item.fecha)
        });
        integrantesDistintoConvenio = integrantesConCobertura.filter(
          x => x.osId != item.osIdPrevBcFecha
        );
      }
      this.textValidateIntegrantesConvenio =
        integrantesDistintoConvenio.length > 0
          ? "El afiliado quedará con un convenio diferente a sus integrantes."
          : "";
      this.showDeleteModal = true;
      this.idsToDelete = item.bcIds;
    },
    async confirmDelete() {
      const response = await this.deleteMovimientoAfiliado(this.idsToDelete);
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.loadMovimientosAfiliado();
      }
    },
    openModal(item) {
      this.openModalEdit = true;
      this.movimientoAfiliado = item;
    },
    closeAndReload() {
      this.openModalEdit = false;
      this.movimientoAfiliado = {};
      this.loadMovimientosAfiliado();
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    toggleModalLiquidacionesMovs() {
      this.modalLiquidacionesMovs = !this.modalLiquidacionesMovs;
    }
  }
};
</script>

<style lang="scss" scoped></style>

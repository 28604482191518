var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.liquidacionesHeaders,"items":_vm.liquidacionesItems,"item-key":"vendedoresLiqProcDetaId","loading":_vm.liquidacionesIsLoading,"show-expand":"","single-expand":"","expanded":_vm.expanded},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-card-title',{staticClass:"pl-1 primary--text"},[_vm._v(_vm._s(_vm.title)+" ")])],1),_c('v-spacer'),_c('v-col',{staticClass:"pt-5",attrs:{"cols":_vm.movHasLiqDesvinculadas ? '3' : '8'}},[_c('v-btn',{staticClass:"to-right",attrs:{"outlined":"","color":'red'},on:{"click":function($event){return _vm.unlinkAllLiquidaciones()}}},[_vm._v(" Desvincular todas ")])],1),_c('v-col',{staticClass:"pt-5 pr-0",attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"30%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pt-2",attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.infoIcon))])]}}])},[_c('span',[_vm._v("Se desvincularán todas las liquidaciones del movimiento seleccionado. ")])])],1),(_vm.movHasLiqDesvinculadas)?_c('v-col',{staticClass:"pt-5",attrs:{"cols":"4"}},[_c('v-btn',{staticClass:"to-right",attrs:{"color":"primary"},on:{"click":function($event){return _vm.linkOrUnlinkLiquidacion()}}},[_vm._v(" Vincular liquidaciones ")])],1):_vm._e(),(_vm.movHasLiqDesvinculadas)?_c('v-col',{staticClass:"pt-5",attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"30%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pt-2",attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.infoIcon))])]}}],null,false,779678352)},[_c('span',[_vm._v("Se volverán a vincular todas las liquidaciones que anteriormente pertenecían al movimiento seleccionado. ")])])],1):_vm._e()],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.linkOrUnlinkLiquidacion(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.desvincularIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Desvincular liquidación")])])]}},{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.handleExpansion(item, isExpanded)}}},'v-icon',attrs,false),on),[_vm._v(_vm._s(isExpanded ? "mdi-account-check" : "mdi-account"))])]}}],null,true)},[_c('span',[_vm._v("Usuarios")])])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('strong',[_vm._v("Fecha alta:")]),_vm._v(" "+_vm._s(item.fechaAlta)+" "),_c('strong',{staticStyle:{"padding-left":"100px"}},[_vm._v("Usuario alta:")]),_vm._v(" "+_vm._s(item.usuarioAlta)+" ")])]}}],null,true)}),_c('DeleteDialog',{attrs:{"titleProp":_vm.titleDelete,"maxWidth":'30%',"textDelete":_vm.textDelete,"isLoading":_vm.btnDeleteIsLoading,"confirmButtonText":_vm.textBtnDelete,"openDelete":_vm.showDeleteModal},on:{"update:openDelete":function($event){_vm.showDeleteModal=$event},"update:open-delete":function($event){_vm.showDeleteModal=$event},"onDeleteItem":function($event){return _vm.confirmDelete()}}}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v(" Cerrar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-card>
    <v-card-title>
      <span class="pl-1 primary--text">{{ title }}</span>
    </v-card-title>
    <v-card-text class="py-0">
      <v-col cols="12" class="pt-0">
        <v-card flat>
          <v-form
            v-model="isFormValid"
            ref="crud-form"
            id="crud-form"
            @submit.prevent="saveEdit()"
          >
            <v-row class="pb-0">
              <!-- Nombre/s -->
              <v-col cols="4" class="py-0">
                <v-text-field
                  type="text"
                  outlined
                  disabled
                  filled
                  readonly
                  dense
                  label="Apellido y nombre"
                  class="theme--light.v-input input"
                  v-model="nombre"
                  autocomplete="off"
                ></v-text-field>
              </v-col>
              <!-- Tipo doc -->
              <v-col cols="2" class="py-0">
                <v-text-field
                  v-model="tipoDocSelected"
                  label="Tipo de documento"
                  disabled
                  filled
                  readonly
                  class="theme--light.v-input input"
                  item-text="value"
                  item-value="id"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <!-- Número de documento -->
              <v-col cols="4" class="py-0">
                <v-text-field
                  type="text"
                  outlined
                  dense
                  disabled
                  filled
                  readonly
                  class="theme--light.v-input input"
                  label="Número de documento"
                  v-model="docAfiliado"
                  autocomplete="off"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
      <!-- Data Table -->
      <v-col cols="12">
        <v-card flat>
          <v-data-table
            :headers="headers"
            item-key="gerBenId"
            show-expand
            :expanded.sync="expanded"
            :items="gerenciadorasItems"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="6" class="py-0 ">
                    <v-card-title>
                      Gerenciadoras
                    </v-card-title>
                  </v-col>
                  <v-col cols="4" class="text-right pt-5">
                    <v-checkbox
                      class="pt-0 checkbox"
                      v-model="fechaVigente"
                      v-bind:label="fechaVigenteText"
                      @change="loadGerenciadorasDeAfiliado()"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="2" class="text-right">
                    <v-btn color="primary" @click="openModal()">
                      Nuevo
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="openModal(item)"
                  >
                    {{ editIcon }}
                  </v-icon>
                </template>
                <span>Editar gerenciadora</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="deleteGerenDeAfiliado(item.gerBenId)"
                  >
                    {{ deleteIcon }}
                  </v-icon>
                </template>
                <span>Eliminar gerenciadora</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.data-table-expand`]="{ item, isExpanded }">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click="handleExpansion(item, isExpanded)"
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    >{{
                      isExpanded ? "mdi-account-check" : "mdi-account"
                    }}</v-icon
                  >
                </template>
                <span>Usuarios</span>
              </v-tooltip>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <strong>Fecha alta:</strong>
                {{ item.fechaAlta }}
                <strong style="padding-left: 100px">Usuario alta:</strong>
                {{ item.usuAlta }}
                <strong style="padding-left: 100px">Fecha modificación:</strong>
                {{ item.fechaModi }}
                <strong style="padding-left: 100px"
                  >Usuario modificación:</strong
                >
                {{ item.usuModi }}
              </td>
            </template>
          </v-data-table>
          <v-dialog
            v-if="openModalEdit"
            v-model="openModalEdit"
            max-width="40%"
            @keydown.esc="closeAndReload"
            persistent
          >
            <EditGerenciadorasDeAfiliado
              :benId="benId"
              :gerenciadorasParams="gerenciadorasParams"
              :fechaConsulta="fechaConsultaAfi"
              @closeAndReload="closeAndReload"
            ></EditGerenciadorasDeAfiliado>
          </v-dialog>
          <DeleteDialog
            :titleProp="titleDelete"
            :maxWidth="'25%'"
            :openDelete.sync="showDeleteModal"
            @onDeleteItem="confirmDelete()"
          />
        </v-card>
      </v-col>

      <v-card-actions>
        <v-row>
          <v-col cols="12" align="end">
            <v-btn color="primary" @click="closeModal" class="to-right">
              Cerrar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
import rules from "@/utils/helpers/rules";
import DeleteDialog from "@/components/shared/DeleteDialog";
import enums from "@/utils/enums/index.js";
import { mapActions } from "vuex";
import EditGerenciadorasDeAfiliado from "./EditGerenciadorasDeAfiliado.vue";

export default {
  components: {
    DeleteDialog,
    EditGerenciadorasDeAfiliado
  },
  name: "GerenciadorasDeAfiliado",
  props: {
    benId: { type: Number },
    afiliado: { type: Object },
    fechaConsultaAfi: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      title: enums.titles.GERENCIADORAS_AFILIADO,
      modalEmpresa: false,
      isFormValid: false,
      deleteIcon: enums.icons.DELETE,
      editIcon: enums.icons.EDIT,
      checkIcon: enums.icons.CHECK_OUTLINE,
      rules: rules,
      tipoDocSelected: null,
      fechaVigente: true,
      fechaVigenteText: null,
      tiposDoc: [],
      expanded: [],
      gerenciadorasItems: [],
      idBenefEmpresa: null,
      docAfiliado: null,
      apellido: null,
      nombre: null,
      openModalEdit: false,
      headers: [
        {
          text: "Gerenciadora",
          sortable: false,
          value: "gerNom"
        },
        {
          text: "Nivel",
          sortable: false,
          value: "nivelNom"
        },
        {
          text: "Fecha desde",
          align: "start",
          sortable: false,
          value: "fechaDesde"
        },
        {
          text: "Fecha hasta",
          sortable: false,
          value: "fechaHasta"
        },

        

        { text: "Acciones", value: "actions", sortable: false, align: "end" },
        { text: "", value: "data-table-expand", align: "end" }
      ],
      titleDelete: "¿Eliminar gerenciadora?",
      showDeleteModal: false,
    };
  },
  created() {
    this.setEmpresasAfiliado(); // Trae datos del afiliado -  nombre y apellido, tipo dni y dni
    this.setTipoDocSelect();
    this.fechaVigenteText =
      "Ver registros vigentes al " + this.formatDate(this.fechaConsultaAfi);
    this.loadGerenciadorasDeAfiliado();
  },
  methods: {
    ...mapActions({
      getGerenciadorasBenef: "afiliaciones/getGerenciadorasBenef",
      deleteGerenciadoraBenef: "afiliaciones/deleteGerenciadoraBenef",
      getTiposDoc: "afiliaciones/getTiposDoc",
      setAlert: "user/setAlert"
    }),
    async loadGerenciadorasDeAfiliado() {
      let fecha = null;
      if (this.fechaVigente) fecha = this.fechaConsultaAfi;
      const response = await this.getGerenciadorasBenef({
        benId: this.afiliado.id,
        fechaConsulta: fecha
      });
      this.gerenciadorasItems = response;
    },
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
    async setTipoDocSelect() {
      const response = await this.getTiposDoc();
      this.tiposDoc = response;
    },
    async setEmpresasAfiliado() {
      this.nombre = this.afiliado.name;
      this.tipoDocSelected = this.afiliado.tipoDocumento;
      this.docAfiliado = this.afiliado.documento;
    },
    deleteGerenDeAfiliado(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    async confirmDelete() {
      const response = await this.deleteGerenciadoraBenef({
        gerBenId: this.idToDelete
      });
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.loadGerenciadorasDeAfiliado();
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    openModal(item) {
      this.openModalEdit = true;
      this.gerenciadorasParams = item;
    },
    closeAndReload() {
      this.openModalEdit = false;
      this.loadGerenciadorasDeAfiliado();
    },
    closeModal() {
      this.$emit("toggleModalGerenciadorasDeAfiliado");
    }
  }
};
</script>

<style scoped>
::v-deep .theme--light.v-input input {
  color: rgb(56, 56, 56);
}

::v-deep .v-select__selection,
.v-select__selection--comma,
.v-select__selection--disabled input {
  color: black !important;
}

::v-deep .v-input__slot {
  cursor: context-menu !important;
}

::v-deep .v-input__slot label {
  margin-bottom: 0% !important;
}
</style>

<template>
  <v-card>
    <v-card-title>
      <span class="pl-1 primary--text">{{ title }}</span>
    </v-card-title>
    <v-card-text class="py-0">
      <v-col cols="12" class="pt-0">
        <v-card flat>
          <v-form
            v-model="isFormValid"
            ref="crud-form"
            id="crud-form"
            @submit.prevent="saveEdit()"
          >
            <v-row class="pb-0">
              <!-- Número afiliado -->
              <v-col cols="12" sm="6" md="2" class="py-0">
                <v-text-field
                  type="text"
                  outlined
                  dense
                  disabled
                  filled
                  readonly
                  class="theme--light.v-input input"
                  label="Grupo familiar"
                  v-model="numeroGrp"
                  autocomplete="off"
                ></v-text-field>
              </v-col>
              <!-- Nombre/s -->
              <v-col cols="12" sm="6" md="5" class="py-0">
                <v-text-field
                  type="text"
                  outlined
                  disabled
                  filled
                  readonly
                  dense
                  label="Apellido y nombre"
                  class="theme--light.v-input input"
                  v-model="nombre"
                  autocomplete="off"
                ></v-text-field>
              </v-col>
              <!-- Tipo doc -->
              <v-col cols="12" sm="6" md="2" class="py-0">
                <v-text-field
                  v-model="tipoDocSelected"
                  label="Tipo de documento"
                  disabled
                  filled
                  readonly
                  class="theme--light.v-input input"
                  item-text="value"
                  item-value="id"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <!-- Número de documento -->
              <v-col cols="12" sm="6" md="2" class="py-0">
                <v-text-field
                  type="text"
                  outlined
                  dense
                  disabled
                  filled
                  readonly
                  class="theme--light.v-input input"
                  label="Documento"
                  v-model="docAfiliado"
                  autocomplete="off"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
      <!-- Data Table -->
      <v-col cols="12">
        <v-card flat>
          <v-data-table
            :headers="headers"
            item-key="movEmpId"
            :items="movimientosEmpresas"
            class="elevation-1"
          >
            <template v-slot:[`item.empDefecto`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" small color="primary">
                    {{ item.empDefecto ? checkIcon : "mdi-circle-small" }}
                  </v-icon>
                </template>
                <span>{{
                  item.empDefecto
                    ? "Empresa por defecto"
                    : "Editar empresa para seleccionar por defecto"
                }}</span>
              </v-tooltip>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="6" class="py-0 ">
                    <v-card-title>Movimientos en empresas</v-card-title>
                  </v-col>
                  <v-col cols="4" class="text-right pt-5">
                    <v-checkbox
                      class="pt-0 checkbox"
                      v-model="fechaVigente"
                      v-bind:label="fechaVigenteText"
                      @change="setEmpresasAfiliado()"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="2" class="text-right">
                    <v-btn color="primary" @click="openModal()">
                      Nuevo
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="openModal(item.benefEmpId, item.empId)"
                  >
                    {{ editIcon }}
                  </v-icon>
                </template>
                <span>Editar empresa</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="deleteMovimiento(item.benefEmpId)"
                  >
                    {{ deleteIcon }}
                  </v-icon>
                </template>
                <span>Eliminar empresa</span>
              </v-tooltip>
            </template>
          </v-data-table>
          <v-dialog
            v-if="openModalEdit"
            v-model="openModalEdit"
            max-width="55%"
            @keydown.esc="closeAndReload"
            persistent
          >
            <EditMovimientoEmpresaAfiliado
              :benefEmpId="idBenefEmpresa"
              :benId="benId"
              :empId="empId"
              :movimientosEmpresas="movimientosEmpresas"
              :fechaConsulta="fechaConsultaAfi"
              @closeAndReload="closeAndReload"
            ></EditMovimientoEmpresaAfiliado>
          </v-dialog>
          <DeleteDialog
            :titleProp="titleDelete"
            :openDelete.sync="showDeleteModal"
            @onDeleteItem="confirmDelete()"
          />
        </v-card>
      </v-col>

      <v-card-actions>
        <v-row>
          <v-col cols="12" align="end">
            <v-btn color="primary" @click="closeModal" class="to-right">
              Cerrar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
import rules from "@/utils/helpers/rules";
import DeleteDialog from "@/components/shared/DeleteDialog";
import enums from "@/utils/enums/index.js";
import { mapActions } from "vuex";
import EditMovimientoEmpresaAfiliado from "./EditMovimientoEmpresaAfiliado.vue";

export default {
  components: {
    DeleteDialog,
    EditMovimientoEmpresaAfiliado
  },
  name: "EditEmpresaAfiliado",
  props: {
    benId: { type: Number },
    afiliado: { type: Object },
    fechaConsultaAfi: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      title: "Movimientos de empresas por afiliado",
      modalEmpresa: false,
      isFormValid: false,
      numeroGrp: null,
      deleteIcon: enums.icons.DELETE,
      editIcon: enums.icons.EDIT,
      checkIcon: enums.icons.CHECK_OUTLINE,
      rules: rules,
      tipoDocSelected: null,
      fechaVigente: true,
      fechaVigenteText: null,
      tiposDoc: [],
      idBenefEmpresa: null,
      docAfiliado: null,
      apellido: null,
      nombre: null,
      openModalEdit: false,
      openModalEmpDefecto: false,
      empId: null,
      headers: [
        {
          text: "Fecha desde",
          align: "start",
          sortable: false,
          value: "fechaDesde"
        },
        {
          text: "Fecha hasta",
          sortable: false,
          value: "fechaHasta"
        },
        {
          text: "Empresa",
          sortable: false,
          value: "empNom"
        },
        {
          text: "Sucursal",
          sortable: false,
          value: "sucursalNom"
        },
        {
          text: "Sueldo",
          sortable: false,
          value: "sueldo"
        },
        {
          text: "Aporte",
          sortable: false,
          value: "aporte"
        },
        {
          text: "Contribución",
          sortable: false,
          value: "contribucion"
        },
        {
          text: "Defecto",
          sortable: false,
          align: "center",
          value: "empDefecto"
        },
        {
          text: "Observaciones",
          sortable: false,
          value: "observaciones"
        },
        { text: "Acciones", value: "actions", sortable: false, align: "end" }
      ],
      movimientosEmpresas: [],
      titleDelete: "¿Eliminar empresa?",
      showDeleteModal: false,
      movimientoEmpresa: {}
    };
  },
  created() {
    this.setEmpresasAfiliado();
    this.setTipoDocSelect();
    this.fechaVigenteText =
      "Ver registros activos al " + this.formatDate(this.fechaConsultaAfi);
  },
  methods: {
    ...mapActions({
      getEmpresasAfiliado: "afiliaciones/getEmpresasAfiliado",
      getTiposDoc: "afiliaciones/getTiposDoc",
      saveEmpresasAfiliado: "afiliaciones/saveEmpresasAfiliado",
      deleteEmpresasAfiliado: "afiliaciones/deleteEmpresasAfiliado",
      saveEmpresaPorDefecto: "afiliaciones/saveEmpresaPorDefecto",
      setAlert: "user/setAlert"
    }),
    async setTipoDocSelect() {
      const response = await this.getTiposDoc();
      this.tiposDoc = response;
    },
    async setEmpresasAfiliado() {
      let fecha = null;
      if (this.fechaVigente) fecha = this.fechaConsultaAfi;

      const data = await this.getEmpresasAfiliado({
        benId: this.benId,
        fechaConsulta: fecha
      });
      this.movimientosEmpresas = data;

      this.nombre = this.afiliado.name;
      this.numeroGrp = this.afiliado.grupoFamiliar;
      this.tipoDocSelected = this.afiliado.tipoDocumento;
      this.docAfiliado = this.afiliado.documento;
    },
    deleteMovimiento(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    async confirmDelete() {
      const response = await this.deleteEmpresasAfiliado({
        benefEmpId: this.idToDelete
      });
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.setEmpresasAfiliado();
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    openModal(idBenefEmpresa, empId) {
      this.openModalEdit = true;
      this.idBenefEmpresa = idBenefEmpresa;
      this.empId = empId;
    },
    closeAndReload() {
      this.openModalEdit = false;
      this.setEmpresasAfiliado(this.benId);
    },
    closeModal() {
      this.$emit("toggleModalEmpresaAfiliado");
    }
  }
};
</script>

<style scoped>
::v-deep .theme--light.v-input input {
  color: rgb(56, 56, 56);
}

::v-deep .v-select__selection,
.v-select__selection--comma,
.v-select__selection--disabled input {
  color: black !important;
}

::v-deep .v-input__slot {
  cursor: context-menu !important;
}

::v-deep .v-input__slot label {
  margin-bottom: 0% !important
}
</style>
